import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import * as _ from 'lodash';

import {
  ModelUpdateSuccess,
  ModelCreateSuccess,
  ModelDeleteSuccess,
  ModelTakeUntakeLinesSuccess,
  ModelEditLineNoteSuccess,
} from '../../features/model/state/model.actions';
import { Model } from '../../../../../../../api/abstracts/planning';
import { MatSnackBar } from '@angular/material';
import { EventsUpdateSuccessAction, EventsDeleteSuccessAction, EventsCreateSuccess } from '../../features/events/state/events.actions';
import {
  VariantsUpdateSuccessAction,
  VariantsDeleteSuccessAction,
  VariantsCreateSuccessAction,
} from '../../features/variants/state/variants.actions';

@Injectable({
  providedIn: 'root',
})
export class StreamService {
  constructor(private store: Store<any>, private snackBarService: MatSnackBar) {
    const events = new EventSource('/api/stream');
    console.log('set up events');
    console.log(events);

    events.onmessage = data => this.onMessage(data);
  }

  private onMessage({ data }) {
    const message = JSON.parse(data);
    if (message.updatedModel) {
      this.store.dispatch(
        ModelUpdateSuccess({
          update: message.updatedModel.map((model: Model) => ({
            id: model.id,
            changes: _(model)
              .omit('id')
              .value() as Partial<Model>,
          })),
        })
      );
    }
    if (message.newModel) {
      this.store.dispatch(ModelCreateSuccess({ entities: [message.newModel], navigateTo: null }));
    }
    if (message.deletedModel) {
      this.store.dispatch(ModelDeleteSuccess({ id: message.deletedModel.id }));
    }
    if (message.takeUntakeModelLines) {
      this.store.dispatch(ModelTakeUntakeLinesSuccess({ modelLines: message.takeUntakeModelLines }));
    }
    if (message.updatedModelLineNote) {
      this.store.dispatch(ModelEditLineNoteSuccess({ ...message.updatedModelLineNote }));
    }
    if (message.newEvent) {
      this.store.dispatch(new EventsCreateSuccess({ event: message.newEvent }));
    }
    if (message.updatedEvent) {
      this.store.dispatch(new EventsUpdateSuccessAction(message.updatedEvent));
    }
    if (message.deletedEvent) {
      this.store.dispatch(new EventsDeleteSuccessAction(message.deletedEvent));
    }
    if (message.newVariant) {
      this.store.dispatch(new VariantsCreateSuccessAction(message.newVariant));
    }
    if (message.updatedVariant) {
      this.store.dispatch(new VariantsUpdateSuccessAction(message.updatedVariant));
    }
    if (message.deletedVariant) {
      this.store.dispatch(new VariantsDeleteSuccessAction(message.deletedVariant));
    }
  }
}
