import { Action } from '@ngrx/store';
import { EventView } from './events.reducer';
import { EventDetails, VariantDetails } from '../../event/event';
import { ModelDetails } from '../../event/components/event-create-edit-stepper-model-details-form/event-create-edit-stepper-model-details-form.component';
import { Event, CreateEventParams, UpdateEventParams } from '../../../../../../../../api/abstracts/planning';

export const EVENTSGET = '[EVENTS] Get';
export const EVENTSGETSUCCESS = '[EVENTS] GetSuccess';
export const EVENTSGETFAILURE = '[EVENTS] GetFailure';
export const EVENTSCREATESUCCESS = '[EVENTS] CreateSuccess';
export const EVENTSUPDATE = '[EVENTS] Update';
export const EVENTSUPDATESUCCESS = '[EVENTS] UpdateSuccess';
export const EVENTSUPDATEFAILURE = '[EVENTS] UpdateFailure';
export const EVENTSTOGGLEVIEWMODEACTION = '[EVENTS] ToggleViewMode';
export const EVENTSUPDATEVARIANT = '[EVENTS] UpdateVariant';
export const EVENTSUPDATEVARIANTSUCCESS = '[EVENTS] UpdateVariantSuccess';
export const EVENTSUPDATEVARIANTFAILURE = '[EVENTS] UpdateVariantFailure';
export const EVENTSDELETE = '[EVENTS] EventsDelete';
export const EVENTSDELETESUCCESS = '[EVENTS] EventsDeleteSuccess';
export const EVENTSDELETEFAILURE = '[EVENTS] EventsDeleteFailure';
export const EVENTSOPTIMIZE = '[EVENTS] EventsOptimize';
export const EVENTSOPTIMIZESUCCESS = '[EVENTS] EventsOptimizeSuccess';
export const EVENTSOPTIMIZEFAILURE = '[EVENTS] EventsOptimizeFailure';
export const EVENTSGETDASHBOARDSTATISTICS = '[EVENTS] EventsGetDashboardStatistics';
export const EVENTSGETDASHBOARDSTATISTICSSUCCESS = '[EVENTS] EventsGetDashboardStatisticsSuccess';
export const EVENTSGETDASHBOARDSTATISTICSFAILURE = '[EVENTS] EventsGetDashboardStatisticsFailure';
export const EVENTSGETCOLLECTIONREPORT = '[EVENTS] EventsGetCollectionReport';
export const EVENTSGETCOLLECTIONREPORTSUCCESS = '[EVENTS] EventsGetCollectionReportSuccess';
export const EVENTSGETCOLLECTIONREPORTFAILURE = '[EVENTS] EventsGetCollectionReportFailure';
export const EVENTSGETDISCOUNTREPORT = '[EVENTS] EventsGetDiscountReport';
export const EVENTSGETDISCOUNTREPORTSUCCESS = '[EVENTS] EventsGetDiscountReportSuccess';
export const EVENTSGETDISCOUNTREPORTFAILURE = '[EVENTS] EventsGetDiscountReportFailure';
export const EVENTSCREATEWIZARD = '[EVENTS] EventsCreateWizard';
export const EVENTSCREATEWIZARDSUCCESS = '[EVENTS] EventsCreateWizardSuccess';
export const EVENTSCREATEWIZARDFAILURE = '[EVENTS] EventsCreateWizardFailure';
export const EVENTSCLEARREPORTINGCACHE = '[EVENTS] EventsClearReportingCache';

export class EventsGetAction implements Action {
  type = EVENTSGET;

  constructor() {}
}

export class EventsCreateWizard implements Action {
  type = EVENTSCREATEWIZARD;

  constructor(
    public payload: {
      eventDetails: EventDetails;
      variantsDetails: VariantDetails[];
      modelsDetails: ModelDetails[];
    }
  ) {}
}

export class EventsCreateWizardSuccess implements Action {
  type = EVENTSCREATEWIZARDSUCCESS;

  constructor(
    public payload: {
      event: Event;
      params: {
        eventDetails: EventDetails;
        variantsDetails: VariantDetails[];
        modelsDetails: ModelDetails[];
      };
    }
  ) {}
}

export class EventsCreateWizardFailure implements Action {
  type = EVENTSCREATEWIZARDFAILURE;

  constructor(public payload) {}
}

export class EventsCreateSuccess implements Action {
  type = EVENTSCREATESUCCESS;

  constructor(
    public payload: {
      event: Event;
    }
  ) {}
}

export class EventsGetSuccessAction implements Action {
  type = EVENTSGETSUCCESS;

  constructor(public payload: Event[]) {}
}

export class EventsGetErrorAction implements Action {
  type = EVENTSGETFAILURE;

  constructor(public payload) {}
}

export class EventsUpdateAction implements Action {
  type = EVENTSUPDATE;

  constructor(public payload: { params: UpdateEventParams; id: number }) {}
}

export class EventsUpdateSuccessAction implements Action {
  type = EVENTSUPDATESUCCESS;

  constructor(public payload: Event) {}
}

export class EventsUpdateFailureAction implements Action {
  type = EVENTSUPDATEFAILURE;

  constructor(public payload) {}
}

export class EventsToggleViewModeAction implements Action {
  type = EVENTSTOGGLEVIEWMODEACTION;

  constructor(public payload: EventView) {}
}

export class EventsDeleteAction implements Action {
  type = EVENTSDELETE;

  constructor(public payload: { id: number }) {}
}

export class EventsDeleteSuccessAction implements Action {
  type = EVENTSDELETESUCCESS;

  constructor(public payload: { id: number }) {}
}

export class EventsDeleteFailureAction implements Action {
  type = EVENTSDELETEFAILURE;

  constructor(public payload) {}
}

export class EventsOptimizeAction implements Action {
  type = EVENTSOPTIMIZE;

  constructor(public payload: { id: number }) {}
}

export class EventsOptimizeSuccessAction implements Action {
  type = EVENTSOPTIMIZESUCCESS;

  constructor(public payload: { id: number }) {}
}

export class EventsOptimizeFailureAction implements Action {
  type = EVENTSOPTIMIZEFAILURE;

  constructor(public payload) {}
}
export class EventsGetDashboardStatisticsAction implements Action {
  type = EVENTSGETDASHBOARDSTATISTICS;

  constructor(public payload: { eventID: string }) {}
}

export class EventsGetDashboardStatisticsSuccessAction implements Action {
  type = EVENTSGETDASHBOARDSTATISTICSSUCCESS;

  constructor(public payload) {}
}

export class EventsGetDashboardStatisticsFailureAction implements Action {
  type = EVENTSGETDASHBOARDSTATISTICSFAILURE;

  constructor(public payload) {}
}

export class EventsGetCollectionReportAction implements Action {
  type = EVENTSGETCOLLECTIONREPORT;

  constructor(public payload: { eventIDs: string[]; regions: string[]; wave: string }) {}
}

export class EventsGetCollectionReportSuccessAction implements Action {
  type = EVENTSGETCOLLECTIONREPORTSUCCESS;

  constructor(public payload: { data; eventIDs: string[]; regions: string[]; wave: string }) {}
}

export class EventsGetCollectionReportFailureAction implements Action {
  type = EVENTSGETCOLLECTIONREPORTFAILURE;
  constructor(public payload) {}
}

export class EventsGetDiscountReportAction implements Action {
  type = EVENTSGETDISCOUNTREPORT;

  constructor(public payload: { eventIDs: string[]; regions: string[]; wave: string }) {}
}

export class EventsGetDiscountReportSuccessAction implements Action {
  type = EVENTSGETDISCOUNTREPORTSUCCESS;

  constructor(public payload) {}
}

export class EventsGetDiscountReportFailureAction implements Action {
  type = EVENTSGETDISCOUNTREPORTFAILURE;
  constructor(public payload) {}
}

export class EventsClearReportingCacheAction implements Action {
  type = EVENTSCLEARREPORTINGCACHE;
  constructor(public payload: { id: number }) {}
}

export type actions =
  | EventsGetSuccessAction
  | EventsUpdateSuccessAction
  | EventsToggleViewModeAction
  | EventsDeleteSuccessAction
  | EventsOptimizeSuccessAction
  | EventsGetCollectionReportSuccessAction
  | EventsGetDiscountReportSuccessAction
  | EventsClearReportingCacheAction;
