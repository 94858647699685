import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { selectRouteParams, selectQueryParams } from './app.reducer';
import { filter, map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouterFacadeService {
  constructor(private store: Store<any>, private router: Router) {}

  getParamFromRouter(paramKey: string): Observable<string> {
    return this.store.pipe(
      select(selectRouteParams),
      filter(routeParams => !!routeParams && !!routeParams[paramKey]),
      map(routeParams => routeParams[paramKey])
    );
  }

  getParamsFromQuery() {
    return this.store.pipe(
      select(selectQueryParams),
      filter(queryParams => !!queryParams)
    );
  }

  getSelectedEventID(): Observable<string> {
    return this.getParamFromRouter('eventid');
  }

  // todo: this should not depend on activated route
  selectRegion(region: string | number, activatedRoute: ActivatedRoute) {
    const { eventid, departmentId, wave } = activatedRoute.snapshot.params;
    // todo: remove models/details (why doesn't ../ and relativeTo work?)
    this.router.navigateByUrl(`/models/event/${eventid}/department/${departmentId}/details/region/${region}/wave/${wave}`)
  }

  // todo: this should not depend on activated route
  selectWave(wave: number, activatedRoute: ActivatedRoute) {
    this.router.navigate([`../${wave}`], { relativeTo: activatedRoute });
  }
}
