import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as _ from 'lodash';

import * as fromModelActions from './model.actions';
import { ModelService } from './model.service';
import { EventModelStatus } from '../../../abstracts/event-status.type';
import { VARIANTSCREATEWIZARDSUCCESS, VariantsCreateWizardSuccessAction } from '../../variants/state/variants.actions';
import { ModelFacadeService } from '../model-facade.service';

@Injectable()
export class ModelEffects {
  loadAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModelActions.ModelLoadAll),
      mergeMap(() =>
        this.modelService.loadAll().pipe(
          map(entities => fromModelActions.ModelLoadAllSuccess({ entities })),
          catchError(error => of(fromModelActions.ModelLoadAllFailure({ error })))
        )
      )
    )
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModelActions.ModelCreate),
      mergeMap(params =>
        this.modelService.create(params).pipe(
          map(entity => {
            let navigateTo;
            if (params.navigateAfterCreate) {
              if (typeof params.navigateAfterCreate === 'object') {
                navigateTo = [...params.navigateAfterCreate, entity.id];
              } else {
                navigateTo = [entity.id];
              }
            }

            return fromModelActions.ModelCreateSuccess({
              entities: [entity],
              navigateTo,
            });
          }),
          catchError(error => of(fromModelActions.ModelCreateFailure({ error })))
        )
      )
    )
  );

  navigateAfterCreate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromModelActions.ModelCreateSuccess),
        tap(({ entities, navigateTo }) => {
          if (navigateTo) {
            const { region, wave } = _.first(entities);
            this.modelFacadeService.selectModels(navigateTo, { use: { region, wave } });
          }
        })
      ),
    { dispatch: false }
  );

  // update$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(fromModelActions.ModelUpdate),
  //     mergeMap(params =>
  //       this.modelService.update(params).pipe(
  //         map(entity =>
  //           fromModelActions.ModelUpdateSuccess({
  //             update: {
  //               id: entity.id,
  //               changes: _(entity)
  //                 .omit('id')
  //                 .value(),
  //             },
  //           })
  //         ),
  //         catchError(error => of(fromModelActions.ModelUpdateFailure({ error })))
  //       )
  //     )
  //   )
  // );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModelActions.ModelDelete),
      mergeMap(({ id }) =>
        this.modelService.delete({ id }).pipe(
          map(() => fromModelActions.ModelDeleteSuccess({ id })),
          catchError(error => of(fromModelActions.ModelDeleteFailure({ error })))
        )
      )
    )
  );

  loadLines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModelActions.ModelLoadLines),
      mergeMap(({ modelIDs }) =>
        this.modelService.loadLines({ modelIDs }).pipe(
          map(allLines =>
            fromModelActions.ModelLoadLinesSuccess({
              updates: _(allLines)
                .map((lines, id) => ({ id, changes: { lines } }))
                .value(),
            })
          ),
          catchError(error => of(fromModelActions.ModelLoadLinesFailure({ error })))
        )
      )
    )
  );

  submit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModelActions.ModelSubmit),
      mergeMap(({ model }) =>
        this.modelService
          .update(+model.id, { modelName: model.name, departmentId: model.departmentId, status: 1, variantId: model.variantId })
          .pipe(
            map(updatedModels =>
              fromModelActions.ModelSubmitSuccess({
                update: updatedModels.map(({ id, status, statusCode }) => ({ id, changes: { status, statusCode } })),
              })
            ),
            catchError(error => of(fromModelActions.ModelSubmitFailure({ error })))
          )
      )
    )
  );

  approve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModelActions.ModelApprove),
      mergeMap(({ model }) =>
        this.modelService
          .update(+model.id, { modelName: model.name, departmentId: model.departmentId, status: 2, variantId: model.variantId })
          .pipe(
            map(updatedModels =>
              fromModelActions.ModelApproveSuccess({
                update: updatedModels.map(({ id, status, statusCode }) => ({ id, changes: { status, statusCode } })),
              })
            ),
            catchError(error => of(fromModelActions.ModelApproveFailure({ error })))
          )
      )
    )
  );

  retract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModelActions.ModelRetract),
      mergeMap(({ model }) =>
        this.modelService
          .update(+model.id, { modelName: model.name, departmentId: model.departmentId, status: 0, variantId: model.variantId })
          .pipe(
            map(updatedModels =>
              fromModelActions.ModelRetractSuccess({
                update: updatedModels.map(({ id, status, statusCode }) => ({ id, changes: { status, statusCode } })),
              })
            ),
            catchError(error => of(fromModelActions.ModelRetractFailure({ error })))
          )
      )
    )
  );

  optimize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModelActions.ModelOptimize),
      mergeMap(({ modelIDs }) =>
        this.modelService.optimizeModel(modelIDs).pipe(
          map(() => fromModelActions.ModelOptimizeSuccess()),
          catchError(error => of(fromModelActions.ModelOptimizeFailure({ error })))
        )
      )
    )
  );

  getLineOverridePrediction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModelActions.ModelGetLineOverridePrediction),
      mergeMap(({ eventID, modelID, override, skuList, variantID }) =>
        this.modelService.getLineOverridePrediction({ eventID, modelID, override, skuList, variantID }).pipe(
          map(lines => fromModelActions.ModelGetLineOverridePredictionSuccess({ lines })),
          catchError(error => of(fromModelActions.ModelGetLineOverridePredictionFailure({ error, modelID, skuList })))
        )
      )
    )
  );

  applyVariant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModelActions.ModelApplyVariant),
      mergeMap(({ model, variantId: updatedVariantId }) =>
        this.modelService
          .update(+model.id, {
            departmentId: model.departmentId,
            modelName: model.name,
            status: model.statusCode,
            variantId: updatedVariantId,
          })
          .pipe(
            map(updatedModels => {
              const { id, variantId } = _.first(updatedModels);
              return fromModelActions.ModelApplyVariantSuccess({ update: { id, changes: { variantId } } });
            }),
            catchError(error => of(fromModelActions.ModelApplyVariantFailure({ error })))
          )
      )
    )
  );

  getDiscountDistribution$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModelActions.ModelGetDiscountDistribution),
      mergeMap(params =>
        this.modelService.getDiscountDistribution(params).pipe(
          map(({ id, metric, modeldistribution }) =>
            fromModelActions.ModelGetDiscountDistributionSuccess({
              update: { id, changes: { distributionOfDiscounts: { [metric]: modeldistribution } } },
            })
          ),
          catchError(error => of(fromModelActions.ModelGetDiscountDistributionFailure({ error })))
        )
      )
    )
  );

  takeUntakeLines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModelActions.ModelTakeUntakeLines),
      mergeMap(({ lines, taken }) =>
        this.modelService.takeUntakeLines({ lines, taken }).pipe(
          map(modelLines => fromModelActions.ModelTakeUntakeLinesSuccess({ modelLines })),
          catchError(error => of(fromModelActions.ModelTakeUntakeLinesFailure({ error })))
        )
      )
    )
  );

  editLineNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModelActions.ModelEditLineNote),
      mergeMap(({ skuId, modelId, note }) =>
        this.modelService.editLineNote({ skuId, modelId, note }).pipe(
          map(({ note, modelId, skuId }) => fromModelActions.ModelEditLineNoteSuccess({ note, modelId, skuId })),
          catchError(error => of(fromModelActions.ModelEditLineNoteFailure({ error })))
        )
      )
    )
  );

  refresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromModelActions.ModelRefresh),
      mergeMap(({ modelIDs }) =>
        this.modelService.refresh(modelIDs).pipe(
          map(({ lines, models }) => fromModelActions.ModelRefreshSuccess({ lines, models })),
          catchError(error => of(fromModelActions.ModelRefreshFailure({ error })))
        )
      )
    )
  );

  createModelWizard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VARIANTSCREATEWIZARDSUCCESS),
      mergeMap((action: VariantsCreateWizardSuccessAction) =>
        this.modelService
          .createWizard(
            action.payload.params.modelsDetails
              .filter(model => !model.id)
              .map(model => {
                let variant;
                const existingVariants = action.payload.params.variantsDetails.filter(v => v.id);
                const newVariants = action.payload.variants;
                const allVariants = [...existingVariants, ...newVariants];
                if (!!model.variant.id) {
                  variant = _.find(allVariants, v => +model.variant.id === +v.id);
                } else {
                  variant = _.find(allVariants, v => model.variant.uuid === v.uuid);
                }
                return {
                  ...model,
                  eventId: +action.payload.event.id,
                  event: action.payload.event,
                  departmentId: model.department.h2Id,
                  minDiscReq: 0,
                  wave: '1',
                  variantId: Number(variant.id),
                  region: model.region.id,
                };
              })
          )
          .pipe(
            map(() => fromModelActions.ModelLoadAll()),
            catchError(error => of(fromModelActions.ModelCreateWizardFailure(error)))
          )
      )
    )
  );

  constructor(private actions$: Actions, private modelService: ModelService, private modelFacadeService: ModelFacadeService) {}
}
