import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output, HostListener, ElementRef } from '@angular/core';

import * as _ from 'lodash';

import { Model } from '../../state/model';
import { EventModelStatus } from '../../../../abstracts';
import { CreateModelParams } from '../../../../../../../../../api/abstracts/planning';
import { lowerWaveWithStatusLessThanApproved } from '../../utilities/lowerWaveWithStatusLessThanApproved';

@Component({
  selector: 'app-omni-model-detail-workflow-dialog',
  templateUrl: './omni-model-detail-workflow-dialog.component.html',
  styleUrls: ['./omni-model-detail-workflow-dialog.component.scss'],
})
export class OmniModelDetailWorkflowDialogComponent implements OnInit, OnChanges {
  @Input()
  waveModels: Model[];

  @Output()
  modelRetracted: EventEmitter<Model> = new EventEmitter();

  @Output()
  modelSubmitted: EventEmitter<Model> = new EventEmitter();

  @Output()
  modelApproved: EventEmitter<Model> = new EventEmitter();

  @Output()
  nextWavePlanned: EventEmitter<CreateModelParams> = new EventEmitter();

  @Output()
  modelRejected: EventEmitter<Model> = new EventEmitter();

  displayStatus: string;
  dialogOpen = false;

  enableAddMultipleWaves = false;

  _waveModels: Array<Model & { disableStatus?: boolean; planNextWave?: boolean; minDiscReq: number }>;

  @HostListener('window:click', ['$event.target'])
  onClick(target: HTMLElement) {
    if (this.elementRef.nativeElement.contains(target) || target.className.indexOf('action-button') > -1) {
      return;
    }

    this.dialogOpen = false;
  }

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {}

  onClickSubmit(model: Model) {
    this.modelSubmitted.emit(model);
  }

  onClickRetract(model: Model) {
    this.modelRetracted.emit(model);
  }

  onClickApprove(model: Model) {
    this.modelApproved.emit(model);
  }

  onClickReject(model: Model) {
    this.modelRejected.emit(model);
  }

  onClickAddAllWaves() {
    this._waveModels.filter(({ planNextWave }) => planNextWave).forEach(model => this.planNextWave(model));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.waveModels) {
      this.displayStatus = _.minBy(changes.waveModels.currentValue as Model[], 'statusCode').status;

      this.enableAddMultipleWaves = false;

      this._waveModels = changes.waveModels.currentValue.map(model => {
        const groupMaxWave = _(model.event.models)
          .filter(({ region, departmentId }) => model.region === region && model.departmentId === departmentId)
          .map(({ wave }) => wave)
          .max();
        const hasGroupMaxWaveVariant = _.find(
          model.event.variants,
          variant => variant.region === model.region && +variant.wave === +groupMaxWave + 1
        );
        const planNextWave =
          groupMaxWave === model.wave &&
          !!hasGroupMaxWaveVariant &&
          ['approved', 'live', 'complete'].indexOf(model.status.toLowerCase()) > -1;

        if (lowerWaveWithStatusLessThanApproved(model.relatedModels, model)) {
          return { ...model, disableStatus: true };
        }

        return { ...model, planNextWave };
      });

      if (_.find(this._waveModels, ({ planNextWave }) => planNextWave)) {
        this.enableAddMultipleWaves = true;
      }
    }
  }

  toggleDialog() {
    this.dialogOpen = !this.dialogOpen;
  }

  planNextWave(model: Model & { minDiscReq: number; event?: any }) {
    const { departmentId, event, minDiscReq, name, region, wave } = model;
    const { id: eventId, variants } = event;
    const { id: variantId } = _(variants)
      .filter(v => v.wave === wave + 1 && v.region === region)
      .first();

    this.nextWavePlanned.emit({ departmentId, eventId: +eventId, minDiscReq, name, region, variantId: +variantId, wave: wave + 1 });
  }

  onClickSubmitAll() {
    this._waveModels.forEach(model => {
      if (model.status === 'Planning') {
        this.modelSubmitted.emit(model);
      }
    });
  }

  onClickRetractAll() {
    this._waveModels.forEach(model => {
      if (model.status === 'Submitted') {
        this.modelRetracted.emit(model);
      }
    });
  }

  onClickRejectAll() {
    this._waveModels.forEach(model => {
      if (model.status === 'Approved') {
        this.modelRejected.emit(model);
      }
    });
  }

  onClickApproveAll() {
    this._waveModels.forEach(model => {
      if (model.status === 'Submitted') {
        this.modelApproved.emit(model);
      }
    });
  }

  getStatusClass(statusCode: string) {
    return {
      0: 'button--white',
      1: 'button--pink',
      2: 'button--light-blue',
      3: 'button--light-green',
      4: 'button--forest-green',
      5: 'button--dark-grey',
    }[EventModelStatus[statusCode]];
  }
}
