import { Store } from '@ngrx/store';

import * as fromRoot from './app.reducer';
import { UserGetCurrentAction } from './user/user.actions';
import { getCurrentUser } from './user/user.reducer';
import { filter, take } from 'rxjs/operators';

declare var firebase;

export function initApplication(store: Store<fromRoot.State>): () => Promise<boolean> {
  return () =>
    new Promise(resolve => {
      firebase.auth();
      store.dispatch(new UserGetCurrentAction());
      if (window.location.pathname.includes('login')) {
        resolve(true);
      } else {
        store
          .select(getCurrentUser)
          .pipe(
            filter(user => !!user.env),
            take(1)
          )
          .subscribe(user => {
            // if (!user.email && String(window.location).indexOf('login') === -1 && String(window.location).indexOf('organizations') === -1) {
            //   window.location.assign('/login');
            // }
            resolve(true);
          });
      }
    });
}
