export interface Event {
  id: number;
  eventType: string;
  eventDetail: string;
  name: string;
  startDate: string;
  endDate: string;
  owner: string;
  lastEdit: string;
  lastEditUser: string;
  status: string;
  note: string;
}

export interface CreateEventParams {
  name: string;
  detail: string;
  type: string;
  starts: string;
  ends: string;
  notes: string;
}

export function isCreateEventParams(obj: any): obj is CreateEventParams {
  return (
    obj &&
    (obj as CreateEventParams).name &&
    (obj as CreateEventParams).detail &&
    (obj as CreateEventParams).type &&
    (obj as CreateEventParams).starts &&
    !!(obj as CreateEventParams).ends
  );
}

export interface UpdateEventParams {
  name: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
  note: string;
  detail: string;
  status: number;
}

export function isUpdateEventParams(obj: any): obj is UpdateEventParams {
  return (
    obj &&
    (obj as UpdateEventParams).name &&
    (obj as UpdateEventParams).startDate &&
    (obj as UpdateEventParams).endDate &&
    (obj as UpdateEventParams).detail &&
    (!!(obj as UpdateEventParams).status || (obj as UpdateEventParams).status === 0)
  );
}
