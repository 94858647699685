import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, map, tap, distinctUntilChanged, withLatestFrom, switchMap } from 'rxjs/operators';

import * as _ from 'lodash';

import { getEvents, getEventById } from '../../events/state/events.selectors';
import { getCurrentUser } from '../../../user/user.reducer';

import * as fromRoot from '../../../app.reducer';
import { Event } from '../../event/event';
import * as mapper from './reporting-detail.mapper';

import { columns, sideBar, autoGroupColumnDef, gridOptions, multipleViewColumns, collectionView } from './reporting-detail.grid-config';

import { ConfigurationSettingsService } from '../../../user/configuration-settings.service';
import { ModelFacadeService } from '../../model/model-facade.service';
import { EventsFacadeService } from '../../events/events-facade.service';
import { Model } from '../../model/state/model';
import { EventFacadeService } from '../../event/event-facade.service';
import { percentDecimalFormatter } from '../../../infra/datagrid/datagrid.formatters';
import { DiscountReportRow } from './reporting-detail-discount-summary/reporting-detail-discount-summary.component';
import { ReportsExportService } from './reports-export.service';
import { UserFacadeService } from '../../../user';

interface IReportRow {
  endStockValue: any;
  divisionDesc: any;
  currentStockValue: any;
  currentCover: any;
  departmentDesc: any;
  startStockUnits: any;
  linesTaken: any;
  spend: any;
  currentSellThrough: any;
  startCover: any;
  newDepth: any;
  startStockValue: any;
  endCover: any;
  modelName: any;
  currentStockUnits: any;
  currentDepth: any;
  startSellThrough: any;
  endStockUnits: any;
  endSellThrough: any;
  currentTotalDepth: any;
  newTotalDepth: any;
  collection: any;
}
export interface CollectionReportRow {
  totalDepthNumerator: number;
  collection_desc: string;
  stockMd: number;
  stockOverMD: number;
  mdDepthNumerator: number;
  mdDepth: number;
  totalDepth: number;
  department_desc: string;
  totalDepthDenominator: number;
  mdDepthDenominator: number;
  stockTotal: number;
  stockMdPct: number;
  dataByDivision: any;
}

@Component({
  selector: 'app-reporting-detail',
  templateUrl: './reporting-detail.component.html',
  styleUrls: ['./reporting-detail.component.scss'],
})
export class ReportingDetailComponent implements OnInit, OnDestroy {
  user$: any;

  events$: Observable<Event[]>;
  event$: Observable<Event>;
  report$: Observable<IReportRow[]>;
  columns;
  sideBar;
  autoGroupColumnDef;
  gridOptions;
  firstId;
  eventIDs;
  collections$: Observable<Array<string>>;
  AWCollections = [];
  SSCollections = [];
  collectionColumns = [];
  view;
  showCollectionReport: boolean;
  collectionAWReport$: Observable<CollectionReportRow[]>;
  collectionSSReport$: Observable<CollectionReportRow[]>;
  selectedRegions = [];
  selectedWave;
  waves = [];
  regions = [];
  collection;
  collectionSubView: string;
  discountReportRows = [];
  discountReport$: Observable<DiscountReportRow[]>;
  discountTypes = [];
  skuTypes = [];
  terminology = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public store: Store<fromRoot.State>,
    private configurationSettingsService: ConfigurationSettingsService,
    private modelFacadeService: ModelFacadeService,
    private eventsFacadeService: EventsFacadeService,
    private eventFacadeService: EventFacadeService,
    private reportsExportService: ReportsExportService,
    private userFacadeService: UserFacadeService
  ) {
    this.userFacadeService.getTerminologySchema().pipe(filter((terminology) => !!terminology), first()).subscribe(terminology => {
      this.terminology = terminology;
      this.columns = columns(this.configurationSettingsService.currency, this.terminology);
    });
    this.sideBar = sideBar;
    this.autoGroupColumnDef = autoGroupColumnDef;
    this.gridOptions = gridOptions;
  }

  ngOnInit() {
    this.view = 'overview';
    this.events$ = this.store.pipe(select(getEvents));

    this.route.params
      .pipe(
        distinctUntilChanged(),
        withLatestFrom(this.modelFacadeService.getAll())
      )
      .subscribe(([{ id, report, collection, regions, wave }, models]) => {
        this.collection = collection;
        this.view = `${report}${collection ? collection : ''}`;
        this.waves = _(models)
          .filter(({ eventId }) => eventId === +id)
          .map(({ wave: modelWave }) => modelWave)
          .uniq()
          .value();
        this.regions = _(models)
          .filter(({ eventId }) => eventId === +id)
          .map(({ region }) => region)
          .uniq()
          .value();
        this.selectedWave = +wave;
        this.selectedRegions = regions.split(',');
        this.showCollectionReport = _.startsWith(this.view, 'collection') ? true : false;
        this.collectionSubView = this.view.slice(-2);
        this.eventIDs = id.split(',').map(d => +d);
        this.firstId = _.first(this.eventIDs);
        this.event$ = this.store.pipe(select(getEventById(_.first(id.split(',')))));

        if (this.eventIDs.length > 1) {
          this.columns = multipleViewColumns(this.configurationSettingsService.currency, this.terminology);
        }

        if (report === 'overview') {
          this.report$ = this.modelFacadeService.get({ eventId: this.eventIDs }).pipe(
            first(),
            map((models: Model[]) => {
              const m = _(
                models
                  .filter(model => model.status !== 'Planning')
                  .map((model: Model) =>
                    model.lines.map(line => ({
                      ...line,
                      modelName: model.name,
                      region: model.region,
                    }))
                  )
              )
                .flatten()
                .groupBy('departmentDesc')
                .map((lines, departmentDesc) => {
                  return _(lines)
                    .groupBy('region')
                    .map((linesByRegion, region) =>
                      _(linesByRegion)
                        .groupBy('collectionDesc')
                        .map((linesByRegionByCollection, collectionDesc) => ({
                          endStockValue: mapper.endStockValue(linesByRegionByCollection),
                          // tslint:disable-next-line: no-string-literal
                          divisionDesc: _.first(linesByRegionByCollection)['divisionDesc'],
                          currentStockValue: mapper.currentStockValue(linesByRegionByCollection),
                          currentCover: mapper.currentCover(linesByRegionByCollection),
                          departmentDesc,
                          startStockUnits: mapper.startStockUnits(linesByRegionByCollection),
                          linesTaken: mapper.linesTaken(linesByRegionByCollection),
                          spend: mapper.spend(linesByRegionByCollection),
                          currentSellThrough: mapper.currentSellThrough(linesByRegionByCollection),
                          startCover: mapper.startCover(linesByRegionByCollection),
                          newDepth: mapper.newDepth(linesByRegionByCollection),
                          startStockValue: mapper.startStockValue(linesByRegionByCollection),
                          endCover: mapper.endCover(linesByRegionByCollection),
                          modelName: _.first(linesByRegionByCollection) && _.first(linesByRegionByCollection).modelName,
                          currentStockUnits: mapper.currentStockUnits(linesByRegionByCollection),
                          currentDepth: mapper.currentDepth(linesByRegionByCollection),
                          startSellThrough: mapper.startSellThrough(linesByRegionByCollection),
                          endStockUnits: mapper.endStockUnits(linesByRegionByCollection),
                          endSellThrough: mapper.endSellThrough(linesByRegionByCollection),
                          region, // _.first(lines) && this.regionPipe.transform(_.first(lines).region),
                          currentTotalDepth: mapper.currentTotalDepth(linesByRegionByCollection),
                          newTotalDepth: mapper.newTotalDepth(linesByRegionByCollection),
                          linesUnderlying: linesByRegionByCollection,
                          collection: collectionDesc,
                        }))
                        .value()
                    )
                    .flatten()
                    .value();
                })
                .flatten()
                .value();

              return m;
            })
          );

          this.report$.subscribe(d => {
            console.log(d);
          });
          this.columns = columns(this.configurationSettingsService.currency, this.terminology);
          this.gridOptions.groupRemoveLowestSingleChildren = false;
        } else if (this.view === 'collectionAW') {
          this.collectionAWReport$ = this.getCollectionData(this.collectionSubView);
          this.collectionAWReport$.subscribe(d => {
            this.collectionColumns = collectionView(this.AWCollections, this.configurationSettingsService.currency, this.collectionSubView, this.terminology);
          });
        } else if (this.view === 'collectionSS') {
          this.collectionSSReport$ = this.getCollectionData(this.collectionSubView);
          this.collectionSSReport$.subscribe(d => {
            this.collectionColumns = collectionView(this.SSCollections, this.configurationSettingsService.currency, this.collectionSubView, this.terminology);
          });
        }
      });
    this.user$ = this.store.pipe(select(getCurrentUser));
    this.user$.pipe(filter((user: any) => !!user.env)).subscribe(user => {
      if (!user.email) {
        this.router.navigate(['/login']);
      }
    });
  }

  ngOnDestroy() {
    this.eventFacadeService.clearEventReportingCache(+this.firstId);
  }

  apply({ id: eventID }: { id: string }) {
    const report = this.showCollectionReport ? 'collection' : 'discount';
    this.router.navigateByUrl(`/reporting/${eventID}/${report}/${this.selectedRegions}/${this.selectedWave}/${this.collection}`);
  }

  switchToCollection({ id: eventId }) {
    this.router.navigateByUrl(`/reporting/${eventId}/collection/${this.selectedRegions}/${this.selectedWave}/${this.collection}`);
  }
  switchToDiscount({ id: eventId }) {
    this.router.navigateByUrl(`/reporting/${eventId}/discount/${this.selectedRegions}/${this.selectedWave}/${this.collection}`);
  }
  switchToForecast({ id: eventID }) {
    this.userFacadeService
      .getDefaultSeason()
      .pipe(
        filter(defaultSeason => !!defaultSeason),
        first()
      )
      .subscribe(defaultSeason => {
        this.router.navigateByUrl(
          `/reporting/live/event/${eventID}/region/${this.selectedRegions}/wave/${this.selectedWave}/season/${defaultSeason}`
        );
      });
  }

  getCollectionData(collectionSubView) {
    return this.eventsFacadeService
      .getCollectionReportData({ eventIDs: this.eventIDs, regions: this.selectedRegions, wave: this.selectedWave })
      .pipe(
        filter(d => d && d.length > 0),
        first(),
        map(d => {
          const sortAlphaNum = (a, b) => a.localeCompare(b, 'en', { numeric: true });
          this.AWCollections = _(d)
            .map(d => d.collection_desc)
            .uniq()
            .value()
            .filter(collection => _.startsWith(collection, 'AW'))
            .sort(sortAlphaNum)
            .concat(['Other', 'Total', 'AW Only']);
          this.SSCollections = _(d)
            .map(d => d.collection_desc)
            .uniq()
            .value()
            .filter(collection => _.startsWith(collection, 'SS'))
            .sort(sortAlphaNum)
            .concat(['Other', 'Total', 'SS Only']);
          const collections = collectionSubView === 'AW' ? this.AWCollections : this.SSCollections;
          const collectionReportRows = [];
          _(d)
            .groupBy('division_desc')
            .map((dataByDivision, division_desc) => {
              _(dataByDivision)
                .groupBy('department_desc')
                .map((data, department_desc) => {
                  const collectionReportRow = {
                    division_desc,
                    department_desc,
                  };
                  _(collections).forEach(collection => {
                    (collectionReportRow[`stockMd_${collection}`] = mapper.newStockByCollection(data, collection, collectionSubView)),
                      (collectionReportRow[`stockTotal_${collection}`] = mapper.stockTotalUnits(data, collection, collectionSubView)),
                      (collectionReportRow[`stockOverMd_${collection}`] = mapper.stockOverMD(data, collection, collectionSubView)),
                      (collectionReportRow[`mdDepthCurrent_${collection}`] = mapper.mdDepthCurrentByCollection(
                        data,
                        collection,
                        collectionSubView
                      )),
                      (collectionReportRow[`totalDepth_${collection}`] = mapper.newTotalDepthByCollection(
                        data,
                        collection,
                        collectionSubView
                      ));
                    collectionReportRow[`mdDepthFull_${collection}`] = mapper.mdDepthFullByCollection(data, collection, collectionSubView);
                    collectionReportRow[`dataByDivision`] = data;
                  });
                  collectionReportRows.push(collectionReportRow);
                })
                .value();
            })
            .flatten()
            .value();
          return collectionReportRows;
        })
      );
  }

  exportToExcel() {
    let AWCollectionRows = this.getCollectionDataForExport('AW');
    let SSCollectionRows = this.getCollectionDataForExport('SS');

    AWCollectionRows = _(AWCollectionRows)
      .flatten()
      .value();
    SSCollectionRows = _(SSCollectionRows)
      .flatten()
      .value();

    this.skuTypes = ['New MD', 'Discounted', 'All'];
    const reportsData = this.getDiscountDataForExport();
    const dataToExport = {
      AWCollections: this.AWCollections,
      AWCollectionRows,
      SSCollections: this.SSCollections,
      SSCollectionRows,
      skuTypes: this.skuTypes,
      reportsData,
    };
    this.reportsExportService.exportExcel(dataToExport);
  }
  getCollectionDataForExport(view) {
    const collectionReport$ = this.getCollectionData(view);
    const collectionRows = [];
    const collectionsList = view === 'AW' ? this.AWCollections : this.SSCollections;
    collectionReport$.subscribe(rows => {
      _(rows)
        .groupBy('division_desc')
        .map((divisionData, division_desc) => {
          let collectionRow = {
            Division: division_desc,
            Department: '',
          };
          // AW Aggregate Rows
          const dataByDivision = _(divisionData)
            .map(d => d.dataByDivision)
            .flatten()
            .value();
          collectionRow = this.getRowValues(collectionRow, dataByDivision, collectionsList, view, divisionData);
          collectionRows.push(collectionRow);
          const allRows = divisionData.map(d => {
            const row = { Department: d.department_desc };
            _(collectionsList).forEach(collection => {
              (row[`stockMd_${collection}`] = d[`stockMd_${collection}`]),
                (row[`stockTotal_${collection}`] = d[`stockTotal_${collection}`]),
                (row[`stockOverMd_${collection}`] = percentDecimalFormatter({ value: d[`stockOverMd_${collection}`] })),
                (row[`mdDepthCurrent_${collection}`] = percentDecimalFormatter({ value: d[`mdDepthCurrent_${collection}`] })),
                (row[`mdDepthFull_${collection}`] = percentDecimalFormatter({ value: d[`mdDepthFull_${collection}`] }));
              row[`totalDepth_${collection}`] = percentDecimalFormatter({ value: d[`totalDepth_${collection}`] });
            });
            return row;
          });
          collectionRows.push(allRows);
        })
        .value();
    });
    // AW Total Rows
    collectionReport$.subscribe(rows => {
      let totalRow = {
        Division: 'Total',
        Department: '',
      };
      const dataByDivision = _(rows)
        .map(d => d.dataByDivision)
        .flatten()
        .value();
      totalRow = this.getRowValues(totalRow, dataByDivision, collectionsList, view);
      collectionRows.push(totalRow);
    });
    return collectionRows;
  }
  getDiscountDataForExport() {
    let discountReportRows = [];
    this.eventsFacadeService
      .getDiscountReportData({ eventIDs: this.eventIDs, regions: this.selectedRegions, wave: this.selectedWave })
      .pipe(
        map(data => {
          this.discountReportRows = [];
          const orderedDiscountTypes = _(data)
            .sortBy('discount_bin')
            .filter((d: any) => d.discount_bin !== 'FULL PRICE')
            .map((d: any) => d.discount_bin)
            .uniq()
            .value();
          this.discountTypes = ['FULL PRICE', ...orderedDiscountTypes];
          _(this.skuTypes).forEach(skuType => {
            _(this.discountTypes).forEach(discount_bin => {
              const dataByDiscount = data.filter((d: any) => d.discount_bin === discount_bin);
              const discountReportRow = {
                skuType,
                discount_bin,
                data: [
                  {
                    sku_count: mapper.totalSkuTypeCount(dataByDiscount, skuType),
                    skuPct: mapper.skuPctByDiscount(data, dataByDiscount, skuType),
                    store_stock_units: mapper.totalStoreStockUnits(dataByDiscount, skuType),
                    storeStockPct: mapper.storeStockPctByDiscount(data, dataByDiscount, skuType),
                    allStockPct: mapper.pctOfAllStock(data, dataByDiscount, skuType),
                    fullAverageDiscount: mapper.fullPriceAverageDiscount(data, dataByDiscount, skuType),
                    currentAverageDiscount: mapper.currentPriceAverageDiscount(data, dataByDiscount, skuType),
                  },
                ],
              };
              this.discountReportRows.push(discountReportRow);
            });
          });
          return this.discountReportRows;
        })
      )
      .subscribe(data => {
        discountReportRows = discountReportRows.concat(data);
      });
    return discountReportRows;
  }

  getRowValues(object, data, collectionList, view, divisionData?) {
    divisionData = divisionData ? divisionData : data;
    _(collectionList).forEach(collection => {
      (object[`stockMd_${collection}`] = mapper.newStockByCollection(data, collection, view)),
        (object[`stockTotal_${collection}`] = mapper.stockTotalUnits(data, collection, view)),
        (object[`stockOverMd_${collection}`] = percentDecimalFormatter({ value: mapper.stockOverMD(data, collection, view) })),
        (object[`mdDepthCurrent_${collection}`] = percentDecimalFormatter({
          value: mapper.mdDepthCurrentByCollection(data, collection, view),
        })),
        (object[`mdDepthFull_${collection}`] = percentDecimalFormatter({ value: mapper.mdDepthFullByCollection(data, collection, view) }));
      object[`totalDepth_${collection}`] = percentDecimalFormatter({ value: mapper.newTotalDepthByCollection(data, collection, view) });
    });
    return object;
  }
}
