import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from 'lodash';

import { Event, CreateEventParams, UpdateEventParams } from '../../../../../../../api/abstracts/planning';
import { thousandsRounded } from '../../infra/datagrid/datagrid.formatters';

export interface EventStatsResponse {
  id: string;
  linesTaken: number;
  estimateSpend: number;
  averageDepth: number;
  estimateSellThrough: number;
  stockByDept: string;
  spendByDept: string;
}

export interface EventCollectionReport {
  event_id: number;
  totalDepthNumerator: number;
  collection_desc: string;
  stockMd: number;
  stockOverMD: number;
  mdDepthNumerator: number;
  mdDepth: number;
  totalDepth: number;
  department_desc: string;
  totalDepthDenominator: number;
  mdDepthDenominator: number;
  stockTotal: number;
  stockMdPct: number;
}

export interface EventDiscountReport {
  event_id: number;
  current_price_sum: number;
  store_stock_units: number;
  final_price_sum: number;
  full_price_sum: number;
  sku_count: number;
  discount_bin: string;
  sku_type: string;
}

export interface EventRegion {
  eventId: number;
  region: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
}

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  eventsPoll$;

  constructor(private http: HttpClient, private store: Store<any>) {}

  loadAll(): Observable<Event[]> {
    return this.http.get<Event[]>('/api/event').pipe(
      map(events =>
        events
          .filter(event => !!event)
          .map(event => {
            return {
              ...event,
              variants: 2,
              models_published: 2,
              departments_published: 2,
            };
          })
      )
    );
  }

  delete({ id }: { id: number }): Observable<void> {
    return this.http.delete<void>(`/api/event/${id}`);
  }

  createEvent(params: CreateEventParams): Observable<Event> {
    return this.http.post<Event>('/api/event', { event: { ...params, notes: params.notes || '' } });
  }

  updateEvent(id: number, params: UpdateEventParams): Observable<Event> {
    return this.http.post<Event>(`/api/event/${id}`, { event: { ...params, note: params.note || '' } });
  }

  optimizeEvent(params: { id: number }) {
    const query = `
      { "event_id": "${params.id}" }
    `;

    return this.http.post<any>('api/event/optimise', query).pipe(map(data => ({ ...params })));
  }

  getEventStatistics({ eventID }): Observable<any> {
    const query = `{
      events(id:"${eventID}") {
        id
        linesTaken
        estimateSpend
        averageDepth
        estimateSellThrough
        stockByDept
        spendByDept
      }
    }`;

    // return this.http.post<{}>('/api', query)
    return this.http.post<{ events: EventStatsResponse[] }>('/api', query).pipe(
      map(({ events }) => {
        const event = _.first(events);
        return {
          ...event,
          stockByDept: JSON.parse(event.stockByDept),
          spendByDept: JSON.parse(event.spendByDept),
          estimateSpend: thousandsRounded(1)({ value: event.estimateSpend / 1000 }),
        };
      })
    );
  }

  getCollectionReport({ eventIDs, regions, wave }: { eventIDs: string[]; regions: string[]; wave: string }) {
    const query = `
        { "event_id": "${eventIDs}", "region": "${regions}", "wave":"${wave}" }
      `;
    return this.http.post<{ events: EventCollectionReport[] }>('api/collection-report', query);
  }

  getDiscountReport({ eventIDs, regions, wave }: { eventIDs: string[]; regions: string[]; wave: string }) {
    const query = `
        { "event_id": "${eventIDs}", "region": "${regions}", "wave": "${wave}" }
      `;
    return this.http.post<{ events: EventDiscountReport[] }>('api/discount-report', query);
  }
}
