import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { Observable, of } from 'rxjs';
import { map, catchError, switchMap, mergeMap } from 'rxjs/operators';

import * as events from './events.actions';

import { EventsService } from '../events.service';

import { GetHelpService } from '../../get-help/get-help.service';
import { Router } from '@angular/router';
import { DATE_FORMAT2 } from '../../../abstracts';

@Injectable()
export class EventsEffects {
  @Effect()
  createWizardEvent$: Observable<Action> = this.actions$.pipe(
    ofType(events.EVENTSCREATEWIZARD),
    switchMap((action: events.EventsCreateWizard) =>
      this.eventsService
        .createEvent({
          name: action.payload.eventDetails.name,
          detail: action.payload.eventDetails.detail,
          type: action.payload.eventDetails.type,
          starts: action.payload.eventDetails.startDate.format(DATE_FORMAT2),
          ends: action.payload.eventDetails.endDate.format(DATE_FORMAT2),
          notes: action.payload.eventDetails.note,
        })
        .pipe(
          map(event => new events.EventsCreateWizardSuccess({ event, params: action.payload })),
          catchError(error => of(new events.EventsCreateWizardFailure(error)))
        )
    )
  );

  @Effect()
  getEvents$: Observable<Action> = this.actions$.pipe(
    ofType(events.EVENTSGET),
    switchMap(() =>
      this.eventsService.loadAll().pipe(
        map(data => new events.EventsGetSuccessAction(data)),
        catchError(error => of(new events.EventsGetErrorAction(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  getEventsFailure$: Observable<any> = this.actions$.pipe(
    ofType(events.EVENTSGETFAILURE),
    switchMap(() => {
      this.getHelpService.showSnackbarError(events.EVENTSGETFAILURE);

      return of(false);
    })
  );

  @Effect()
  updateEvent$: Observable<Action> = this.actions$.pipe(
    ofType(events.EVENTSUPDATE),
    switchMap((action: events.EventsUpdateAction) =>
      this.eventsService.updateEvent(action.payload.id, action.payload.params).pipe(
        map(data => new events.EventsUpdateSuccessAction(data)),
        catchError(error => of(new events.EventsUpdateFailureAction(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  updateEventFailure$: Observable<any> = this.actions$.pipe(
    ofType(events.EVENTSUPDATEFAILURE),
    switchMap((action: events.EventsUpdateFailureAction) => {
      this.getHelpService.showSnackbarError(
        events.EVENTSUPDATEFAILURE,
        'Your event was not saved due to a communication error with the Sparkbox server.'
      );

      return of(false);
    })
  );

  @Effect()
  deleteEvent$: Observable<Action> = this.actions$.pipe(
    ofType(events.EVENTSDELETE),
    switchMap((action: events.EventsDeleteAction) =>
      this.eventsService.delete(action.payload).pipe(
        switchMap(() => [new events.EventsDeleteSuccessAction({ id: action.payload.id })]),
        catchError(error => of(new events.EventsDeleteFailureAction(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  deleteEventSuccessNavigate$: Observable<any> = this.actions$.pipe(
    ofType(events.EVENTSDELETESUCCESS),
    mergeMap(() => {
      this.router.navigateByUrl('/events');
      return of(false);
    })
  );

  @Effect({ dispatch: false })
  deleteEventFailure$: Observable<any> = this.actions$.pipe(
    ofType(events.EVENTSUPDATEFAILURE),
    switchMap((action: events.EventsDeleteFailureAction) => {
      this.getHelpService.showSnackbarError(
        events.EVENTSDELETEFAILURE,
        'Your event was not deleted due to a communication error with the Sparkbox server.'
      );

      return of(false);
    })
  );

  @Effect()
  optimizeEvent$: Observable<any> = this.actions$.pipe(
    ofType(events.EVENTSOPTIMIZE),
    switchMap((action: events.EventsOptimizeAction) =>
      this.eventsService.optimizeEvent(action.payload).pipe(
        switchMap((data: { id: number }) => [
          new events.EventsOptimizeSuccessAction(data),
          // new models.ModelsOptimizeSuccessAction({ eventID: data.id }),
        ]),
        catchError(error => of(new events.EventsOptimizeFailureAction(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  optimizeEventFailure$: Observable<any> = this.actions$.pipe(
    ofType(events.EVENTSOPTIMIZEFAILURE),
    switchMap((action: events.EventsOptimizeFailureAction) => {
      this.getHelpService.showSnackbarError(
        events.EVENTSOPTIMIZEFAILURE,
        'Your event was not optimized due to a communication error with the Sparkbox server.'
      );

      return of(false);
    })
  );

  @Effect()
  getEventStatistics$: Observable<any> = this.actions$.pipe(
    ofType(events.EVENTSGETDASHBOARDSTATISTICS),
    switchMap((action: events.EventsGetDashboardStatisticsAction) =>
      this.eventsService.getEventStatistics({ eventID: action.payload.eventID }).pipe(
        map(data => new events.EventsGetDashboardStatisticsSuccessAction(data)),
        catchError(error => of(new events.EventsDeleteFailureAction(error)))
      )
    )
  );

  @Effect()
  getCollectionReport$: Observable<Action> = this.actions$.pipe(
    ofType(events.EVENTSGETCOLLECTIONREPORT),
    switchMap((action: events.EventsGetCollectionReportAction) =>
      this.eventsService.getCollectionReport(action.payload).pipe(
        map(data => new events.EventsGetCollectionReportSuccessAction({ data, ...action.payload })),
        catchError(error => of(new events.EventsGetCollectionReportFailureAction(error)))
      )
    )
  );

  @Effect()
  getDiscountReport$: Observable<Action> = this.actions$.pipe(
    ofType(events.EVENTSGETDISCOUNTREPORT),
    switchMap((action: events.EventsGetDiscountReportAction) =>
      this.eventsService.getDiscountReport(action.payload).pipe(
        map(data => new events.EventsGetDiscountReportSuccessAction({ data, ...action.payload })),
        catchError(error => of(new events.EventsGetDiscountReportFailureAction(error)))
      )
    )
  );

  constructor(
    private eventsService: EventsService,
    private actions$: Actions,
    private getHelpService: GetHelpService,
    private router: Router
  ) {}
}
