import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import * as _ from 'lodash';
import { map, pluck } from 'rxjs/operators';

import { CURRENT_DATE_OVERRIDE_FOR_MODEL_LINE_PREDICTION, GRAPH_QL_ENDPOINT } from '../../../abstracts';
import { Line, GetLineOverridePredictionParams, LineOverridePrediction, DiscountDistribution, DiscountDistributionMetric } from './model';
import { Observable, forkJoin, of } from 'rxjs';

import {
  Model as AbstractModel,
  CreateModelParams,
  UpdateModelParams,
  ModelLineTakenDetail,
  UpdateModelLineNoteParams,
} from '../../../../../../../../api/abstracts/planning';

function normalizeLineMapper(line) {
  return {
    age: line.age,
    avgCover: line.avg_cover,
    avgSales: line.avg_sales,
    avgSalesStore: line.avg_sales_store,
    avgSellingPrice: line.avg_selling_price,
    categoryDesc: line.category_desc,
    changeRecommended: line.change_recommended,
    collectionDesc: line.collection_desc,
    colourDesc: line.colour_desc,
    commitment: line.commitment,
    currentMargin: line.current_margin,
    currentPrice: line.current_price,
    currentSt: line.current_st,
    departmentDesc: line.department_desc,
    divisionDesc: line.division_desc,
    exitDate: line.exit_date,
    fcstEndCover: line.fcst_end_cover,
    fcstEndSales: line.fcst_end_sales,
    fcstEndSt: line.fcst_end_st,
    fcstEndStock: line.fcst_end_stock,
    fcstMdSales: line.fcst_md_sales,
    fcstPremdSales: line.fcst_premd_sales,
    fcstSpend: line.fcst_spend,
    fcstStartSales: line.fcst_start_sales,
    fcstStartSt: line.fcst_start_st,
    fcstStartStock: line.fcst_start_stock,
    finalDisc: line.final_disc,
    finalMargin: line.final_margin,
    finalPrice: line.final_price,
    fullPrice: line.full_price,
    lwCover: line.lw_cover,
    lwSales: line.lw_sales,
    mdCount: line.md_count,
    modelId: line.model_id,
    modelName: line.model_name,
    optDisc: line.opt_disc,
    optMargin: line.opt_margin,
    optPrice: line.opt_price,
    ovrPrice: line.ovr_price,
    season: line.season,
    skuDesc: line.sku_desc,
    skuId: line.sku_id,
    stockOnHand: line.stock_on_hand,
    storesCount: line.stores_count,
    taken: line.taken,
    totalSales: line.total_sales,
    totalStock: line.total_stock,
    unitCost: line.unit_cost,
    note: line.note,
    ageStore: line.age_store,
    lastPriceChangeDate: line.last_price_change_date,
    lastWavePrice: line.last_wave_price,
    mdCountEvent: line.md_count_event,
    currentPriceEvent: line.current_price_event,
    salesGammeId: line.sales_gamme_id,
  };
}

function normalizeLineOverridePredictionMapper(
  eventID,
  modelID
): (params: {
  skuid: string;
  optimised_price: number;
  gross_sales_event: number;
  gross_sales_lw_event: number;
  stock_event: number;
  fcst_spend: number;
  final_disc: number;
  final_margin: number;
  fcst_end_st: number;
  final_price: number;
}) => Partial<Line> {
  return ({
    skuid,
    optimised_price,
    gross_sales_event,
    gross_sales_lw_event,
    stock_event,
    fcst_spend,
    final_disc,
    final_margin,
    fcst_end_st,
    final_price,
  }) => ({
    skuId: skuid,
    eventID,
    modelId: modelID,
    ovrPrice: optimised_price,
    fcstMdSales: gross_sales_event,
    fcstEndSales: gross_sales_lw_event,
    fcstEndStock: stock_event,
    fcstSpend: fcst_spend,
    finalDisc: final_disc,
    finalMargin: final_margin,
    finalPrice: final_price,
    fcstEndSt: fcst_end_st,
  });
}

@Injectable({
  providedIn: 'root',
})
export class ModelService {
  constructor(private http: HttpClient) {}

  loadAll(): Observable<AbstractModel[]> {
    return this.http.get<AbstractModel[]>('/api/model');
  }

  createWizard(models: CreateModelParams[]): Observable<AbstractModel[]> {
    const createModels = models.map(model => this.create(model));

    return forkJoin(createModels);
  }

  create(model: CreateModelParams): Observable<AbstractModel> {
    return this.http.post<AbstractModel>('/api/model', { model });
  }

  update(id: number, params: UpdateModelParams): Observable<AbstractModel[]> {
    return this.http.post<AbstractModel[]>(`/api/model/${id}`, { model: params });
  }

  delete({ id }): Observable<void> {
    return this.http.delete<void>(`/api/model/${id}`);
  }

  takeUntakeLines({ lines, taken }: { lines: any[]; taken: 0 | 1 }): Observable<ModelLineTakenDetail[]> {
    const modelLines = lines.map(line => `${line.skuId}_${line.model.id}`);

    return this.http.post<ModelLineTakenDetail[]>('/api/model/line/take', { taken, modelLines });
  }

  editLineNote({ skuId, modelId, note }: UpdateModelLineNoteParams): Observable<UpdateModelLineNoteParams> {
    return this.http.post<UpdateModelLineNoteParams>('/api/model/line/note', { modelLineNote: { skuId, modelId, note } });
  }

  optimizeModel(modelIDs: Array<string>) {
    const query = `{
      "model_ids":[${modelIDs}]
    }`;

    return this.http.post<any>('/api/model/optimise', query).pipe(map(data => _.first(data.updateModel)));
  }

  loadLines({ modelIDs }: { modelIDs: Array<string | number> }): Observable<{ [key: number]: Line[] }> {
    if (modelIDs.length === 0) {
      return of({});
    }

    const body = {
      model_ids: modelIDs.map(modelID => Number(modelID)),
    };

    return this.http.post<any>('/api/lines', body).pipe(
      map(data =>
        _(data.lines.map(normalizeLineMapper))
          .groupBy('modelId')
          .value()
      )
    );
  }

  // would be nice if this api would return the entire line instead of a partial
  // so that it could be updated in the model entity reducer
  // also it would be nice if the response from the api either didn't need to be mapped
  // or could use the existing line mapper (model.service#normalizeLineMapper)
  getLineOverridePrediction({
    eventID,
    modelID,
    variantID,
    skuList,
    override,
  }: GetLineOverridePredictionParams): Observable<Array<Partial<Line>>> {
    const body = {
      eventID,
      modelID,
      variantID,
      skuList,
      override,
      CURRENT_DATE_OVERRIDE_FOR_MODEL_LINE_PREDICTION,
    };

    return this.http
      .post<LineOverridePrediction[]>('/ml/prediction', body)
      .pipe(map(data => data.map(normalizeLineOverridePredictionMapper(eventID, modelID))));
  }

  getDiscountDistribution({
    metric,
    id,
  }: {
    metric: DiscountDistributionMetric;
    id: number;
  }): Observable<{ modeldistribution: DiscountDistribution; id: number; metric: DiscountDistributionMetric }> {
    const query = `{
      modeldistribution(metric:"${metric}", modelId:"${id}") {
        bucket10
        bucket15
        bucket20
        bucket25
        bucket30
        bucket35
        bucket40
        bucket45
        bucket50
        bucket55
        bucket60
        bucket65
        bucket70
        bucket75
      }
    }`;

    return (
      this.http
        .post<{ modeldistribution: Array<DiscountDistribution> }>(GRAPH_QL_ENDPOINT, query)
        // return this.http.post<any>('/mock/api/modeldistribution', query)
        .pipe(
          map(({ modeldistribution }) => ({
            modeldistribution: _.first(modeldistribution),
            id,
            metric,
          }))
        )
    );
  }

  refresh(modelIDs: Array<string | number>) {
    return forkJoin({ lines: this.loadLines({ modelIDs }), models: this.loadAll() });
  }
}
