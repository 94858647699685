import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ReportingComponent } from './features/reporting/reporting.component';

import { SettingsComponent } from './features/settings/settings.component';
import { EventsComponent } from './features/events/events.component';
import { ModelsComponent } from './features/model/components/models/models.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';

import { LoginComponent } from './user/login/login.component';
import { OrgComponent } from './features/sb-admin/org/org.component';
import { OrgDetailComponent } from './features/sb-admin/org/org-detail/org-detail.component';
import { OrgGridComponent } from './features/sb-admin/org/org-grid/org-grid.component';
import { AdminPanelComponent } from './features/admin-panel/admin-panel.component';
import { AuthGuard } from './auth/auth.guard';
import { EventsGuard } from './features/events/events.guard';
import { RolesComponent } from './features/sb-admin/roles/roles.component';
import { SbAdminComponent } from './features/sb-admin/sb-admin.component';
import { MerchHierarchyGuard } from './features/model/merch-hierarchy.guard';
import { ReportingEventsGridComponent } from './features/reporting/reporting-events-grid/reporting-events-grid.component';
import { ReportingDetailComponent } from './features/reporting/reporting-detail/reporting-detail.component';
import { VariantsGuard } from './features/variants/variants.guard';
import { ReportingDetailGuard } from './features/reporting/reporting-detail/reporting-detail.guard';
import { PriceLaddersGuard } from './features/settings/price-ladders/price-ladders.guard';
import { ZonesGuard } from './user/zones.guard';
import { GetHelpComponent } from './features/get-help/get-help.component';
import { OmniModelDetailComponent } from './features/model/components/omni-model-detail/omni-model-detail.component';
import { MultiModelDetailGuard } from './features/model/components/omni-model-detail/multi-model-detail.guard';
import { ModelGuard } from './features/model/model.guard';
import { SeasonsGuard } from './user/seasons.guard';
import { ReportingDetailCollectionGuard } from './features/reporting/reporting-detail/reporting-detail-collection.guard';
import { ReportingDetailDiscountGuard } from './features/reporting/reporting-detail/reporting-detail-discount.guard';
import { EventDetailComponent } from './features/event/components/event-detail/event-detail.component';
import { DiscountLaddersGuard } from './features/settings/discount-ladders/discount-ladders.guard';
import { ReportingLiveComponent } from './features/reporting/reporting-live/reporting-live.component';
import { ReportingEventLiveComponent } from './features/reporting/reporting-event-live/reporting-event-live.component';
import { TerminologySchemaGuard } from './user/terminology-schema.guard';
const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [EventsGuard, ModelGuard, VariantsGuard],
  },
  {
    path: 'admin',
    component: AdminPanelComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sbadmin',
    component: SbAdminComponent,
    children: [
      {
        path: 'roles',
        component: RolesComponent,
      },
      {
        path: 'organizations',
        component: OrgComponent,
        children: [
          {
            path: ':id',
            component: OrgDetailComponent,
          },
          {
            path: '',
            component: OrgGridComponent,
          },
        ],
      },
    ],
  },
  { path: 'login', component: LoginComponent },
  {
    path: 'reporting',
    component: ReportingComponent,
    canActivate: [EventsGuard, VariantsGuard, ModelGuard, TerminologySchemaGuard],
    children: [
      {
        path: '',
        component: ReportingEventsGridComponent,
      },
      {
        path: 'live/event/:eventid/region/:regions/wave/:wave/season/:season',
        component: ReportingEventLiveComponent,
        canActivate: [SeasonsGuard, ZonesGuard],
      },
      {
        path: ':id/:report/:regions/:wave/:collection',
        component: ReportingDetailComponent,
        canActivate: [ZonesGuard, ReportingDetailCollectionGuard, ReportingDetailDiscountGuard],
      },
    ],
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [PriceLaddersGuard, MerchHierarchyGuard, SeasonsGuard, DiscountLaddersGuard, ZonesGuard],
  },
  {
    path: 'events',
    component: EventsComponent,
    canActivate: [EventsGuard, ModelGuard, VariantsGuard, MerchHierarchyGuard, ZonesGuard, TerminologySchemaGuard],
  },
  {
    path: 'events/:eventid',
    component: EventDetailComponent,
    canActivate: [EventsGuard, ModelGuard, VariantsGuard, MerchHierarchyGuard, ZonesGuard, TerminologySchemaGuard],
  },
  {
    path: 'models/event',
    canActivate: [ModelGuard, MerchHierarchyGuard, ZonesGuard, PriceLaddersGuard, VariantsGuard, EventsGuard, TerminologySchemaGuard],
    children: [
      {
        path: ':eventid/department/:departmentId/details/region/:region/wave/:wave',
        component: OmniModelDetailComponent,
        canActivate: [MultiModelDetailGuard],
      },
    ],
  },
  {
    path: 'models',
    component: ModelsComponent,
    canActivate: [ModelGuard, MerchHierarchyGuard, ZonesGuard, EventsGuard, VariantsGuard, TerminologySchemaGuard],
  },
  {
    path: 'get-help',
    component: GetHelpComponent,
  },
  {
    path: 'live-reporting',
    component: ReportingLiveComponent,
    canActivate: [SeasonsGuard, ZonesGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
