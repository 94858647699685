import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

declare var firebase;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token$ = this.auth.getToken();

    return token$.pipe(
      switchMap(token =>
        next.handle(
          req.clone({
            setHeaders: {
              'Content-Type': 'application/json; charset=utf-8',
              Accept: 'application/json',
              Authorization: `Bearer ${token}`,
            },
          })
        )
      ),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            if (!window.location.pathname.includes('login')) {
              setTimeout(() => window.location.assign(`/login?redirect=${window.location.pathname}`), 200);
            }
          }
          console.log('error');
          console.log(err);
          console.log(req);
        }
        return of(err);
      })
    );
  }
}
