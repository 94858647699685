import { Component, OnInit } from '@angular/core';
import { UserFacadeService } from 'projects/sbx-ui-planning/src/app/user/user-facade.service';

@Component({
  selector: 'app-vat-rate-cell-editor',
  templateUrl: './vat-rate-cell-editor.component.html',
  styleUrls: ['./vat-rate-cell-editor.component.scss']
})
export class VatRateCellEditorComponent implements OnInit {
  params: any;
  value;
  private cancelBeforeStart = false;

  constructor(private userFacadeService: UserFacadeService) { }

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value;
    this.cancelBeforeStart = (params.charPress && '1234567890.'.indexOf(params.charPress) < 0);
  }

  ngOnInit() {
  }

  isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  isCancelAfterEnd(): boolean {
    const isNonDecimalNumber = /^(0?(\.\d+)?|1)$/.test(this.value); // Check for decimal number between 0 and 1
    return !isNonDecimalNumber;
  }

  onChange(event) {
    if (this.isCancelAfterEnd()) {
      this.value = this.params.value;
    } else {
    this.userFacadeService.setVatRate({regionId: this.params.data.id, vatRate: +this.value });
    }
  }

}
