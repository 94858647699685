import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as _ from 'lodash';

import { Event, EventDetails, VariantDetails } from './event';
import { RouterFacadeService } from '../../router-facade.service';
import { getEvents } from '../events/state/events.selectors';
import {
  EventsDeleteAction,
  EventsCreateWizard,
  EventsUpdateAction,
  EventsClearReportingCacheAction,
} from '../events/state/events.actions';
import { ModelDetails } from './components/event-create-edit-stepper-model-details-form/event-create-edit-stepper-model-details-form.component';
import { Moment } from 'moment';
import { PartialArray, filterCol } from '../../infra/utilities';
import { UpdateEventParams } from '../../../../../../../api/abstracts/planning';

@Injectable({
  providedIn: 'root',
})
export class EventFacadeService {
  constructor(private router: Router, private routerFacadeService: RouterFacadeService, private store: Store<any>) {}

  get(params: PartialArray<Event>): Observable<Array<Event>> {
    return this.getAll().pipe(map(events => filterCol(events)(params)));
  }

  getAll(): Observable<Event[]> {
    return this.store.pipe(select(getEvents));
  }

  selectEvent(eventID: number) {
    this.router.navigateByUrl(`/events/${eventID}`);
  }

  getSelectedEvent(): Observable<Event> {
    return this.routerFacadeService.getSelectedEventID().pipe(
      switchMap((ids: string) => this.get({ id: ids.split(',').map(id => +id) })),
      map(d => _(d).first())
    );
  }

  deleteEvent(params: { id: number }) {
    this.store.dispatch(new EventsDeleteAction(params));
  }

  createWizardEvent(params: { eventDetails: EventDetails; variantsDetails: VariantDetails[]; modelsDetails: ModelDetails[] }) {
    this.store.dispatch(new EventsCreateWizard(params));
  }

  update(params: UpdateEventParams & { id: number }) {
    this.store.dispatch(new EventsUpdateAction({ params, id: params.id }));
  }

  clearEventReportingCache(id: number) {
    this.store.dispatch(new EventsClearReportingCacheAction({ id }));
  }
}
