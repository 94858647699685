import * as _ from 'lodash';

import * as variants from './variants.actions';
import { Variant } from '../../../../../../../../api/abstracts/planning';

export interface IVariantsState {
  entities: { [key: string]: Variant };
  ids: string[];
  loading: boolean;
}

export const InitialState: IVariantsState = {
  entities: {},
  ids: null,
  loading: false,
};

export function reducer(state = InitialState, action: variants.actions) {
  switch (action.type) {
    case variants.VARIANTSGET: {
      return {
        ...state,
        loading: true,
      };
    }
    case variants.VARIANTSGETSUCCESS: {
      return {
        ...state,
        loading: false,
        entities: _.keyBy(action.payload, 'id'),
        ids: _.map(action.payload, (entity: Variant) => entity.id),
      };
    }
    case variants.VARIANTSUPDATESUCCESS: {
      const variant = action.payload as Variant;

      return {
        ...state,
        entities: {
          ...state.entities,
          [variant.id]: {
            ...state.entities[variant.id],
            ...variant,
          },
        },
      };
    }
    case variants.VARIANTSCREATESUCCESS: {
      const variant = action.payload as Variant;

      return {
        ...state,
        entities: {
          ...state.entities,
          [variant.id]: variant,
        },
        ids: [...state.ids, variant.id],
      };
    }
    case variants.VARIANTSCREATEWIZARDSUCCESS: {
      const { variants: newVariants } = action.payload as { variants: Variant[] };

      const entities = _(newVariants)
        .keyBy('id')
        .value();

      return {
        ...state,
        entities: {
          ...state.entities,
          ...entities,
        },
        ids: newVariants.map(({ id }) => id),
      };
    }
    case variants.VARIANTDELETESUCCESS: {
      const id = action.payload['id'];
      const newState = { ...state, ids: state.ids.filter(d => +d !== +id) };
      delete newState.entities[id];

      return newState;
    }
    default:
      return state;
  }
}
