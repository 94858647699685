import { Component, OnInit, Input } from '@angular/core';
import { Model } from '../../state/model';
import { columns, sideBar, gridOptions } from '../models/models.grid-config';
import { RegionPipe, UserFacadeService } from '../../../../user';
import { ColDef } from 'ag-grid-community';
import { filter, first } from 'rxjs/operators';

@Component({
  selector: 'app-model-grid',
  templateUrl: './model-grid.component.html',
  styleUrls: ['./model-grid.component.scss'],
})
export class ModelGridComponent implements OnInit {
  columns: ColDef[];
  sidebar = sideBar;
  gridOptions = gridOptions;
  terminology = [];
  @Input()
  models: Model[];

  constructor(private regionPipe: RegionPipe, private userFacadeService: UserFacadeService) {
    this.userFacadeService.getTerminologySchema().pipe(filter((terminology) => !!terminology), first()).subscribe(terminology => {
      this.terminology = terminology;
      this.columns = columns(this.regionPipe, this.terminology);
    });
  }

  ngOnInit() {
    gridOptions.groupDefaultExpanded = 1;
  }
}
