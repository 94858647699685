import { FormGroup, Validators, AbstractControl } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { VariantDetails } from '../features/event/event';


export function isOptimisationTargetValid(formGroup: FormGroup) {
  switch (formGroup.value.optimisationType) {
    case 'Sell Through': {
      if (isBetween(0, 100)(formGroup.controls.optimisationTarget)) {
        formGroup.controls.optimisationTarget.setErrors({ notBetween0and100: true });

        return { notValid: true };
      }
      break;
    }
    case 'Margin Currency': {
      if (Validators.pattern(/^[0-9]*$/)(formGroup.controls.optimisationTarget)) {
        formGroup.controls.optimisationTarget.setErrors({ NaN: true });

        return { notValid: true };
      }
      break;
    }
    case 'Revenue Currency': {
      if (Validators.pattern(/^[0-9]*$/)(formGroup.controls.optimisationTarget)) {
        formGroup.controls.optimisationTarget.setErrors({ NaN: true });

        return { notValid: true };
      }
      break;
    }
  }

  formGroup.controls.optimisationTarget.updateValueAndValidity({ onlySelf: true });
  if (formGroup.controls.minDisc.valid) {
    formGroup.controls.optimisationTarget.setErrors(null);
  }

  return null;
}

export function isMaxDiscLessThanMinDisc(formGroup: FormGroup) {
  if (+formGroup.value.maxDisc < +formGroup.value.minDisc) {
    formGroup.controls.minDisc.setErrors({ maxDiscLessThanMinDisc: true });
    formGroup.controls.maxDisc.setErrors({ maxDiscLessThanMinDisc: true });
    return { notValid: true };
  }

  formGroup.controls.minDisc.updateValueAndValidity({ onlySelf: true });
  formGroup.controls.maxDisc.updateValueAndValidity({ onlySelf: true });

  if (formGroup.controls.minDisc.valid) {
    formGroup.controls.minDisc.setErrors(null);
  }
  if (formGroup.controls.maxDisc.valid) {
    formGroup.controls.maxDisc.setErrors(null);
  }

  return null;
}

export function isStartDateBeforeEndDate(formGroup: FormGroup) {
  if (formGroup.value.startDate > formGroup.value.endDate) {
    formGroup.controls.startDate.setErrors({ startDateAfterEndDate: true });
    formGroup.controls.endDate.setErrors({ startDateAfterEndDate: true });

    return { notValid: true };
  }

  formGroup.controls.startDate.updateValueAndValidity({ onlySelf: true });
  formGroup.controls.endDate.updateValueAndValidity({ onlySelf: true});

  if (formGroup.controls.startDate.valid) {
    formGroup.controls.startDate.setErrors(null);
  }
  if (formGroup.controls.endDate.valid) {
    formGroup.controls.endDate.setErrors(null);
  }

  return null;
}

export function isBetween(min: number, max: number) {
  return (control: AbstractControl) => {
    try {
      if ((!control.value && Number(control.value) !== 0) || Number(control.value) < min || Number(control.value) > max) {
        return { [`notBetween${min}and${max}`]: true };
      }
    } catch (e) {
      return { [`notBetween${min}and${max}`]: true };
    }

    return null;
  };
}

export function isMasterRegionValid(formGroup: FormGroup) {
  if (formGroup.value.department && formGroup.value.masterRegion) {
    const masterRegionLines = _(_.keys(formGroup.value.department.lineCount)).includes(formGroup.value.masterRegion.description);
    if ( !masterRegionLines ) {
        formGroup.controls.masterRegion.setErrors({ isMasterRegionInvalid: true });
        return { notValid: true };
    }
  }
}

export function invalidVariantStartDate(variantsDetails) {
  return (control: AbstractControl) => {
    try {
     if (variantsDetails && variantsDetails.length > 0) {
        const start = control.value;
        const inValidStartDate = _.findIndex(variantsDetails, (v: VariantDetails) =>
        moment(v.startDate).toDate() < moment(start).toDate());
        if (inValidStartDate > -1) {
          return { [`invalidVariantStartDate`]: true };
        }
      }
    } catch (e) {
      return { [`invalidVariantStartDate`]: true };
    }

    return null;
  };
}

export function invalidVariantEndDate(variantsDetails) {
  return (control: AbstractControl) => {
    try {
      if (variantsDetails && variantsDetails.length > 0) {
        const end = control.value;
        const inValidEndDate = _.findIndex(variantsDetails, (v: VariantDetails) =>
          moment(v.endDate).toDate() > moment(end).toDate());
        if (inValidEndDate > -1) {
          return { [`invalidVariantEndDate`]: true };
        }
      }
    } catch (e) {
      return { [`invalidVariantEndDate`]: true };
    }

    return null;
  };
}
