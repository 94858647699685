import * as events from './events.actions';

import * as _ from 'lodash';
import { IEvent } from '../../../abstracts/event.interface';
import { Event } from '../../event/event';
import { Event as AbstractEvent } from '../../../../../../../../api/abstracts/planning';
import { EventStatsResponse } from '../events.service';

export interface IEventsState {
  entities: { [key: string]: Event };
  ids: any[];
  loading: boolean;
  viewMode: EventView;
}

export type EventView = 'grid' | 'calendar';

export const InitialState: IEventsState = {
  entities: {},
  ids: null,
  loading: false,
  viewMode: 'calendar',
};

export function reducer(state = InitialState, action: events.actions) {
  switch (action.type) {
    case events.EVENTSGET: {
      return {
        ...state,
        loading: true,
      };
    }
    case events.EVENTSGETSUCCESS: {
      return {
        ...state,
        ids: _.map(action.payload as any, entity => entity.id),
        entities: _.keyBy(action.payload as any, 'id'),
        loading: false,
      };
    }
    case events.EVENTSCREATESUCCESS: {
      const event = action.payload.event as AbstractEvent; // why doesn't the typeguard work........

      return {
        ...state,
        ids: [...state.ids, event.id],
        entities: { ...state.entities, [event.id]: event },
      };
    }
    case events.EVENTSCREATEWIZARDSUCCESS: {
      const { event } = action.payload as { event: IEvent };

      return {
        ...state,
        ids: [...state.ids, event.id],
        entities: { ...state.entities, [event.id]: event },
      };
    }
    case events.EVENTSUPDATESUCCESS: {
      const event = action.payload as IEvent;

      return {
        ...state,
        entities: {
          ...state.entities,
          [event.id]: {
            ...state.entities[event.id],
            ...event,
          },
        },
      };
    }
    case events.EVENTSTOGGLEVIEWMODEACTION: {
      const viewMode = action.payload as EventView;

      return {
        ...state,
        viewMode,
      };
    }

    case events.EVENTSDELETESUCCESS: {
      const payload = action.payload as { id: number };

      return {
        ...state,
        entities: {
          ..._.omit(state.entities, +payload.id),
        },
        ids: state.ids.filter(id => +id === +payload.id),
      };
    }

    case events.EVENTSGETDASHBOARDSTATISTICSSUCCESS: {
      // @ts-ignore;
      const payload = action.payload as EventStatsResponse;
      return {
        ...state,
        entities: {
          ...state.entities,
          // @ts-ignore;
          [action.payload.id]: {
            // @ts-ignore;
            ...state.entities[action.payload.id],
            ...payload,
          },
        },
      };
    }

    case events.EVENTSGETCOLLECTIONREPORTSUCCESS: {
      const newState = _.cloneDeep(state);
      _(action.payload.data)
        .groupBy('event_id')
        .forEach((data, eventID) => {
          const collectionReport = newState.entities[eventID].eventCollectionReport;
          if (!collectionReport) {
            newState.entities[eventID].eventCollectionReport = {};
          }
          const regionKey = action.payload.regions.join();
          const region = newState.entities[eventID].eventCollectionReport[regionKey];
          const { wave } = action.payload;
          if (region) {
            region[wave] = data;
          } else {
            newState.entities[eventID].eventCollectionReport[regionKey] = { [wave]: data };
          }
        });

      return newState;
    }

    case events.EVENTSGETDISCOUNTREPORTSUCCESS: {
      const newState = _.cloneDeep(state);
      _(action.payload.data)
        .groupBy('event_id')
        .forEach((data, eventID) => {
          const collectionReport = newState.entities[eventID].eventDiscountReport;
          if (!collectionReport) {
            newState.entities[eventID].eventDiscountReport = {};
          }
          const regionKey = action.payload.regions.join();
          const region = newState.entities[eventID].eventDiscountReport[regionKey];
          const { wave } = action.payload;
          if (region) {
            region[wave] = data;
          } else {
            newState.entities[eventID].eventDiscountReport[regionKey] = { [wave]: data };
          }
        });

      return newState;
    }

    case events.EVENTSCLEARREPORTINGCACHE: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.payload.id]: { ...state.entities[action.payload.id], eventDiscountReport: {}, eventCollectionReport: {} },
        },
      };
    }

    default:
      return state;
  }
}
