export interface Model {
  id: string;
  eventId: number;
  type: null;
  name: string;
  owner: string;
  lastEdit: string;
  lastEditUser: string;
  statusCode: number;
  variantId: number;
  departmentId: string;
  region: string;
  channel: string;
  minDiscReq: number;
  wave: number;
  taskStatus: boolean;
  lastOptimisationDate: string;
  totalLines: number;
  linesTaken: number;
  estimateSpend: number;
  averageDepth: number;
  estimateSellThrough: number;
  regionCode: string;
  regionWebCode: string;
  departmentDesc: string;
  status: string;
  lines?: any[];
}

export interface CreateModelParams {
  eventId: number;
  name: string;
  departmentId: string;
  minDiscReq: number;
  wave: string | number;
  variantId: number;
  region: any;
}

export function isCreateModelParams(obj: any): obj is CreateModelParams {
  return (
    obj &&
    (obj as CreateModelParams).eventId &&
    (obj as CreateModelParams).name &&
    (obj as CreateModelParams).departmentId &&
    String((obj as CreateModelParams).minDiscReq) &&
    (obj as CreateModelParams).wave &&
    (obj as CreateModelParams).variantId &&
    (obj as CreateModelParams).region
  );
}

export interface UpdateModelParams {
  modelName: string;
  departmentId: string;
  status: number;
  variantId: number;
}

export function isUpdateModelParams(obj: any): obj is UpdateModelParams {
  return (
    obj &&
    !!(obj as UpdateModelParams).modelName &&
    !!(obj as UpdateModelParams).departmentId &&
    String((obj as UpdateModelParams).status) &&
    !!(obj as UpdateModelParams).variantId
  );
}

export interface ModelDetail {
  totalLines: number;
  linesTaken: number;
  estimateSpend: number;
  averageDepth: number;
  estimateSellThrough: number;
  compliance: number;
}

export interface ModelLineTakenDetail {
  skuId: string;
  modelId: number;
  lineCount: number;
  taken: number;
}

export interface UpdateModelLineNoteParams {
  note: string;
  modelId: number;
  skuId: string;
}

export function isUpdateModelLineNoteParams(obj: any): obj is UpdateModelLineNoteParams {
  return (
    obj &&
    !!(obj as UpdateModelLineNoteParams).modelId &&
    !!(obj as UpdateModelLineNoteParams).note &&
    !!(obj as UpdateModelLineNoteParams).skuId
  );
}

// export function PModelDetailToModelDetailMapper(pModelDetail: PModelDetail): ModelDetail {
//   return _(pModelDetail)
//     .omit('model_id')
//     .mapKeys();
// }
