import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, combineLatest} from 'rxjs';

import { Store } from '@ngrx/store';

import { tap, switchMap, catchError, first } from 'rxjs/operators';
import { UserFacadeService } from './user-facade.service';
import { GetTerminologySchemaAction } from './user.actions';

@Injectable({
    providedIn: 'root',
})
export class TerminologySchemaGuard implements CanActivate {
    constructor(private store: Store<any>, private userFacadeService: UserFacadeService) { }

    getFromStoreOrAPI(): Observable<any> {
        return combineLatest(this.userFacadeService.getTerminologySchemaID(), this.userFacadeService.getTerminologySchema()).pipe(
            tap(([terminologySchemaID, terminologySchema]) => {
                if (!terminologySchema) {
                    this.store.dispatch(new GetTerminologySchemaAction({ terminologySchemaID }));
                }
            }),
            first()
        );
    }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.getFromStoreOrAPI().pipe(
            switchMap(() => of(true)),
            catchError(() => of(false))
        );
    }
}
