import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as _ from 'lodash';
import { map, first, withLatestFrom, takeUntil } from 'rxjs/operators';
import { Observable, Subject, combineLatest } from 'rxjs';
import * as moment from 'moment';

import { UserFacadeService } from '../../../../user';
import { MerchHierarchyPipe } from '../../merch-hierarchy.pipe';
import { LinesExportService } from '../../lines-export.service';
import { Model, Line } from '../../state/model';
import { ModelFacadeService } from '../../model-facade.service';
import { RouterFacadeService } from '../../../../router-facade.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ModelPlanningDialogComponent } from '../model-planning-dialog/model-planning-dialog.component';
import { CreateModelParams, Model as AbstractModel } from '../../../../../../../../../api/abstracts/planning';

@Component({
  selector: 'app-omni-model-detail',
  templateUrl: './omni-model-detail.component.html',
  styleUrls: ['./omni-model-detail.component.scss'],
})
export class OmniModelDetailComponent implements OnInit, OnDestroy {
  lines$: Observable<Array<Line & { model: Model }>>;
  filterLines$: Observable<Array<Line & { model: Model }>>;
  regions$: Observable<Array<string | number>>;
  regionSelected$: Observable<string | number>;
  waveRegionModels$: Observable<AbstractModel[]>;
  filters$ = new Subject();
  redrawRowSubject$ = new Subject();
  maxWave$: Observable<number>;
  ////
  title$: Observable<any>;
  modelProcessingStatus$: Observable<boolean>;
  selectedModelsRegion$: Observable<AbstractModel[]>;
  disableOptimise$: Observable<boolean>;

  quickFilterText: string;
  filterValueChanged;

  selectedWave = 2;

  submitting: boolean;
  retracting: boolean;

  componentDestroy$ = new Subject();
  numberOfWaveModels: number;
  allowDelete: boolean;
  modelsToOptimise = [];
  allSeasonsLines$: Observable<Array<Line & { model: Model }>>;
  constructor(
    private merchHierarchyPipe: MerchHierarchyPipe,
    private linesExportService: LinesExportService,
    private modelFacadeService: ModelFacadeService,
    private userFacadeService: UserFacadeService,
    private routerFacadeService: RouterFacadeService,
    private activatedRoute: ActivatedRoute,
    private snackBarService: MatSnackBar,
    private dialog: MatDialog
  ) {}

  ngOnDestroy(): void {
    this.componentDestroy$.next();
  }

  ngOnInit(): void {
    // related models are used to apply lock on model-lines which have related lower model waves
    const models$ = this.modelFacadeService.getSelectedModelsWithRelatedModels();

    const selectedWave$ = this.modelFacadeService.getSelectedWave();

    selectedWave$.pipe(takeUntil(this.componentDestroy$)).subscribe(wave => (this.selectedWave = wave));

    this.regionSelected$ = this.modelFacadeService.getSelectedRegion();

    const seasonsToFilter$ = this.userFacadeService.getSelectedSeasons();

    const includedSkus$ = this.userFacadeService.getIncludedSkus();

    const excludedSkus$ = this.userFacadeService.getExcludedSkus();

    // take all the models
    // @ts-ignore
    this.allSeasonsLines$ = models$.pipe(
      withLatestFrom(this.regionSelected$, selectedWave$),
      map(([models, selectedRegion, selectedWave]) =>
        _(models)
          // filter by models for selected region
          .filter(model => model.region === selectedRegion)
          .filter(model => !!model.lines)
          // filter by models for selected wave
          .filter(model => model.wave === selectedWave)
          // take lines for all those models
          .map(model => model.lines.map(line => _.assign(line, { model })))
          .flatten()
          .value()
      )
    );

    // filtering the lines by season
    this.lines$ = this.allSeasonsLines$.pipe(
      withLatestFrom(seasonsToFilter$, includedSkus$, excludedSkus$),
      map(([lines, seasons, includedIds, excludedIds]) => {
        if (seasons && seasons.length > 0) {
          return lines.filter(
            line => (_(seasons).includes(line.season) || _(includedIds).includes(line.skuId)) && !_(excludedIds).includes(line.skuId)
          );
        }

        return lines;
      })
    );

    this.maxWave$ = models$.pipe(
      map(models =>
        _(models)
          .map(model => model.wave)
          .max()
      )
    );

    this.regions$ = models$.pipe(
      map(models =>
        _(models)
          .map(model => model.region)
          .uniq()
          .value()
      )
    );

    this.selectedModelsRegion$ = this.modelFacadeService.getSelectedModelsRegionWithRelatedModels();

    this.waveRegionModels$ = this.selectedModelsRegion$.pipe(
      withLatestFrom(selectedWave$),
      map(([models, selectedWave]) => {
        const waveModels = models.filter(model => model.wave === selectedWave);
        const maxRegionWave = _(models)
          .map(model => model.wave)
          .max();
        this.allowDelete = maxRegionWave <= selectedWave ? true : false;
        this.numberOfWaveModels = waveModels.length;
        return waveModels;
      })
    );

    this.title$ = models$.pipe(
      map(models => {
        const modelNames = _(models)
          .map((model: Model) => model.name)
          .uniq()
          .value();
        if (modelNames.length === 1) {
          const modelName = _.first(modelNames);
          return [modelName, modelName];
        } else {
          return [`Custom Selection: ${Object.keys(models).length} models`, modelNames.join(', ')];
        }
      })
    );

    this.modelProcessingStatus$ = this.selectedModelsRegion$.pipe(
      map(models => {
        return models.map((model: Model) => model.taskStatus).indexOf(true) > -1;
      })
    );

    this.disableOptimise$ = combineLatest([this.waveRegionModels$, this.userFacadeService.getLastDataRefresh()]).pipe(
      map(([models, lastDataRefresh]) => {
        this.modelsToOptimise = models
          .filter(
            model =>
              model.lastOptimisationDate != null &&
              (moment(lastDataRefresh).isAfter(model.lastOptimisationDate) && model.status === 'Planning')
          )
          .map(model => {
            return model.id;
          });
        if (this.modelsToOptimise.length > 0) {
          return false;
        } else {
          return true;
        }
      })
    );
  }

  onRegionSelected(region: string | number): void {
    this.routerFacadeService.selectRegion(region, this.activatedRoute);
  }

  onWaveChanged(event: number): void {
    this.routerFacadeService.selectWave(event, this.activatedRoute);
  }

  onQuickFilterChanged(quickFilterText: string): void {
    this.quickFilterText = quickFilterText;
  }

  onModelRetracted(model: Model) {
    this.modelFacadeService.retract(model);
    this.redrawRowSubject$.next();
  }

  onModelSubmitted(model: Model) {
    this.modelFacadeService.submit(model);
    this.redrawRowSubject$.next();
  }

  onModelApproved(model: Model) {
    this.modelFacadeService.approve(model);
    this.redrawRowSubject$.next();
  }

  deleteModel(): void {
    const message =
      this.numberOfWaveModels > 1
        ? `Are you sure you want to delete ${this.numberOfWaveModels} models?`
        : 'Are you sure you want to delete this model?';
    if (this.allowDelete) {
      if (window.confirm(message)) {
        this.modelFacadeService.deleteSelectedRegionModels();
      }
    } else {
      const snackbarStr = `Cannot delete model as future waves exist`;
      const snackBarRef = this.snackBarService.open(snackbarStr, 'Dismiss', {
        verticalPosition: 'top',
      });
      snackBarRef._dismissAfter(10000);
    }
  }

  exportTo(type, point) {
    if (type === 'csv') {
      this.lines$
        .pipe(
          first(),
          map(lines =>
            lines.filter(
              d =>
                d.taken === 'Yes' &&
                ((d.ovrPrice && d.ovrPrice !== (d.lastWavePrice ? d.lastWavePrice : d.currentPrice)) ||
                  (!d.ovrPrice && d.optPrice !== (d.lastWavePrice ? d.lastWavePrice : d.currentPrice)))
            )
          )
        )
        .subscribe(lines => {
          _(lines).forEach(line => {
            if (line.model.regionWebCode) {
              lines.push({ ...line, webCodeRow: true });
            }
          });
          this.linesExportService.exportCsv(
            lines.map(d => ({
              ...d,
              numOp: '',
              tarifNodhos: d.webCodeRow ? d.model.regionWebCode : d.model.regionCode,
              naturePrix: d.model.eventName,
              refId: d.skuId.substring(0, 6),
              colourNodhos: d.skuId.substring(6, 16),
              colourCM: '',
              pct:
                d.model.region === 'IT' || point === 'discount'
                  ? Math.floor(Number((1 - d.finalPrice / d.currentPriceEvent).toFixed(3)) * 100)
                  : '',
              tauxPct: Math.floor(Number((1 - d.finalPrice / d.currentPriceEvent).toFixed(3)) * 20) * 5,
              wave: d.model.type,
              region: d.model.region,
              gamme: '',
            }))
          );
        });
    }

    if (type === 'txt') {
      this.lines$
        .pipe(
          first(),
          map(lines =>
            lines.filter(
              d =>
                d.taken === 'Yes' &&
                ((d.ovrPrice && d.ovrPrice !== (d.lastWavePrice ? d.lastWavePrice : d.currentPrice)) ||
                  (!d.ovrPrice && d.optPrice !== (d.lastWavePrice ? d.lastWavePrice : d.currentPrice)))
            )
          )
        )
        .subscribe(lines => {
          _(lines).forEach(line => {
            if (line.model.regionWebCode) {
              lines.push({ ...line, webCodeRow: true });
            }
          });
          this.linesExportService.exportTxt(
            lines.map(d => ({
              ...d,
              numOp: '',
              tarifNodhos: d.webCodeRow ? d.model.regionWebCode : d.model.regionCode,
              naturePrix: d.model.eventName,
              refId: d.skuId.substring(0, 6),
              colourNodhos: d.skuId.substring(6, 12),
              colourCM: '',
              pct:
                d.model.region === 'IT' || point === 'discount'
                  ? Math.floor(Number((1 - d.finalPrice / d.currentPriceEvent).toFixed(3)) * 100)
                  : '',
              tauxPct: Math.floor(Number((1 - d.finalPrice / d.currentPriceEvent).toFixed(3)) * 20) * 5,
              wave: d.model.type,
              region: d.model.region,
              gamme: '',
            }))
          );
        });
    }

    if (type === 'xlsx') {
      this.lines$
        .pipe(
          first(),
          map(lines =>
            lines.filter(
              d =>
                d.taken === 'Yes' &&
                ((d.ovrPrice && d.ovrPrice !== (d.lastWavePrice ? d.lastWavePrice : d.currentPrice)) ||
                  (!d.ovrPrice && d.optPrice !== (d.lastWavePrice ? d.lastWavePrice : d.currentPrice)))
            )
          )
        )
        .subscribe(lines => {
          _(lines).forEach(line => {
            if (line.model.regionWebCode) {
              lines.push({ ...line, webCodeRow: true });
            }
          });
          this.linesExportService.exportXlsx(
            lines.map(d => ({
              ...d,
              numOp: '',
              tarifNodhos: d.webCodeRow ? d.model.regionWebCode : d.model.regionCode,
              naturePrix: d.model.eventName,
              refId: d.skuId.substring(0, 6),
              colourNodhos: d.skuId.substring(6, 12),
              colourCM: '',
              pct:
                d.model.region === 'IT' || point === 'discount'
                  ? Math.floor(Number((1 - d.finalPrice / d.currentPriceEvent).toFixed(3)) * 100)
                  : '',
              tauxPct: Math.floor(Number((1 - d.finalPrice / d.currentPriceEvent).toFixed(3)) * 20) * 5,
              wave: d.model.type,
              region: d.model.region,
              gamme: '',
              updatedFinalPrice: this.updateFinalPrice(d.finalPrice, d.unitCost, d.categoryDesc),
            }))
          );
        });
    }
  }

  optimizeModel() {
    if (window.confirm('Are you sure you want to optimise this model?')) {
      this.modelFacadeService.optimizeSelectedRegionModels(this.modelsToOptimise);
    }
  }

  onNextWavePlanned(newModel: CreateModelParams) {
    this.modelFacadeService
      .getSelectedModels()
      .pipe(first())
      .subscribe(models => {
        this.modelFacadeService.create(newModel, { navigateAfterCreate: models.map(({ id }) => +id) });
      });
  }

  onModelRejected(model: Model) {
    this.modelFacadeService.retract(model);
    this.redrawRowSubject$.next();
  }

  private updateFinalPrice(finalPrice, unitCost, categoryDesc) {
    return finalPrice < unitCost ? this.merchHierarchyPipe.priceLadderNextValue(categoryDesc, unitCost) : finalPrice;
  }
  filterChangedInGrid(e) {
    this.filterValueChanged = e;
  }
  toggleModelPlanning(region: string) {
    const dialogRef = this.dialog.open(ModelPlanningDialogComponent, {
      data: {
        lines: this.allSeasonsLines$,
        region,
        wave: this.selectedWave,
      },
    });
  }
}
