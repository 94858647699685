import { Component, OnInit, Input } from '@angular/core';
import { ColDef, GridOptions } from 'ag-grid-community';
import { IZone } from '../../../user';
import { VatRateCellEditorComponent } from './vat-rate-cell-editor/vat-rate-cell-editor.component';

@Component({
  selector: 'app-vat-rate-assignment',
  templateUrl: './vat-rate-assignment.component.html',
  styleUrls: ['./vat-rate-assignment.component.scss']
})
export class VatRateAssignmentComponent implements OnInit {
  @Input()
  regions: IZone[];

  gridOptions: GridOptions = {
    deltaRowDataMode: true,
    getRowNodeId: (data) => data.id,
    onRowDataUpdated: (e) => {
      this.showLoadingOverlay = false;
      e.api.sizeColumnsToFit();
    },
    onGridReady: (e) => {
      e.api.sizeColumnsToFit();
    }
  };

  columns: ColDef[] = [
    { field: 'description', headerName: 'Region', width: 30},
    { field: 'vatRate', headerName: 'VAT Rate', cellRendererFramework: VatRateCellEditorComponent, width: 30},
  ];
  showLoadingOverlay;
  constructor() {}

  ngOnInit() {}

}
