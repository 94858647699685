import { Component, OnInit, forwardRef, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { VariantDetails } from '../../event';

export interface ModelDetails {
  division;
  department;
  region;
  name;
  variant;
  wave?;
}

@Component({
  selector: 'app-event-create-edit-stepper-model-details-form',
  templateUrl: './event-create-edit-stepper-model-details-form.component.html',
  styleUrls: ['./event-create-edit-stepper-model-details-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EventCreateEditStepperModelDetailsFormComponent),
      multi: true,
    },
  ],
})
export class EventCreateEditStepperModelDetailsFormComponent implements OnInit, OnChanges {
  @Input()
  variants: VariantDetails[];

  @Input()
  region;

  @Output()
  modelRemoved = new EventEmitter();

  @Input()
  disableSelectFields: boolean;

  value: ModelDetails & { id? } = {
    division: undefined,
    department: undefined,
    region: undefined,
    name: undefined,
    variant: undefined,
    id: undefined,
  };

  wave1Variants = [];

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.variants) {
      this.wave1Variants = changes.variants.currentValue.filter(variant => variant.region.id === this.region.id);
    }
  }

  onChange: (_: any) => void = (_: any) => {};

  onTouched: () => void = () => {};

  updateChanges() {
    this.onChange(this.value);
  }

  writeValue(value: ModelDetails & { id? }): void {
    this.value = value;
    this.updateChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  removeRow() {
    this.modelRemoved.next();
  }
}
