import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as user from './user.actions';
import { IOrg } from '../features/sb-admin/org/state/org.reducer';

import * as _ from 'lodash';
import { MerchHierarchyLineage } from '../features/model/state/model';

export interface IZone {
  id: string;
  description: string;
  code: string;
}

export interface IUserState {
  name: string;
  url: string;
  role: string[];
  env: string;
  views: string[];
  email: string;
  org: IOrg;
  zones?: IZone[];
  merchHierarchy: any;
  seasonsList: any;
  terminologySchema;
}

export const InitialState: IUserState = {
  name: null,
  url: null,
  role: [],
  env: null,
  views: [],
  email: null,
  org: null,
  zones: null,
  merchHierarchy: null,
  seasonsList: null,
  terminologySchema: null,
};

export function reducer(state = InitialState, action: user.actions): IUserState {
  switch (action.type) {
    case user.USERGETCURRENTUSERSUCCESS:
    case user.USERGETADMINSUCCESS:
      return {
        ...state,
        name: action.payload.name || null,
        url: action.payload.url || null,
        role: action.payload.role || null,
        env: action.payload.env || null,
        views: (action.payload.org && action.payload.org.products) || [],
        email: action.payload.email || null,
        org: action.payload.org || null,
      };
    case user.USERSETORGSETTINGSUCCESS:
      return {
        ...state,
        org: {
          ...state.org,
          settings: {
            ...state.org.settings,
            [action.payload.name]: action.payload.value,
          },
        },
      };
    case user.USERGETORGZONESSUCCESS:
      return {
        ...state,
        zones: _.sortBy(action.payload, d => d.description), // should be done closer to view
      };
    case user.USERGETMERCHHIERARCHYSUCCESS: {
      return {
        ...state,
        merchHierarchy: action.payload.map((d: MerchHierarchyLineage) => ({
          ...d,
          id: `${[d.h1Id, d.h2Id, d.h3Id, d.h4Id, d.h5Id].join('-')}`,
        })),
      };
    }
    case user.USERGETORGSEASONSSUCCESS: {
      return {
        ...state,
        seasonsList: action.payload,
      };
    }
    case user.USERGETTERMINOLOGYSCHEMASUCCESS: {
      return {
        ...state,
        terminologySchema: action.payload,
      };
    }
    default:
      return state;
  }
}

export const getUserState = createFeatureSelector<IUserState>('user');

export const getCurrentUser = createSelector(getUserState, (state: IUserState) => ({ ...state }));

export const getLastDataRefresh = createSelector(getUserState, (state: IUserState) => state.org.last_data_refresh);

export const getCurrentUserName = createSelector(getUserState, (state: IUserState) => state.name);

export const getUrl = createSelector(getUserState, (state: IUserState) => state.url);

export const getCurrentUserRole = createSelector(getUserState, (state: IUserState) => state.role);

export const getCurrentEnv = createSelector(getUserState, (state: IUserState) => state.env);

export const getUserViews = createSelector(getUserState, (state: IUserState) => state.views);

export const isCurrentUserAdmin = createSelector(
  getUserState,
  (state: IUserState) => state.role && state.role.indexOf('Administrator') > -1
);

export const isCurrentUserSuperuser = createSelector(
  getUserState,
  (state: IUserState) => state.role && state.role.indexOf('Superuser') > -1
);

export const isCurrentOrgMarkdownAndPromos = createSelector(
  getUserState,
  (state: IUserState) => state.org.products.indexOf('promos') > -1 && state.org.products.indexOf('markdown') > -1
);

export const getOrgSetting = (orgSettings: Array<string>): any =>
  createSelector(getUserState, (state: IUserState) => {
    const obj = {};
    orgSettings.forEach(setting => {
      obj[setting] = state.org.settings[setting];
    });

    return obj;
  });

export const getOrgCurrency = createSelector(getUserState, (state: IUserState) => state.org && state.org.settings['currency'] || 'EUR');

export const getOrgZones = createSelector(getUserState, (state: IUserState) => state.zones);

export const getOrgSeasons = createSelector(getUserState, (state: IUserState) => state.seasonsList);

export const getMerchHierarchy = createSelector(getUserState, (state: IUserState) => state.merchHierarchy);

export const getSeasons = createSelector(getUserState, (state: IUserState) => state.seasonsList);

export const getOrgTerminologySchema = createSelector(getUserState, (state: IUserState) => state.terminologySchema);
