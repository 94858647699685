import { Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as _ from 'lodash';

import { getEvents, getEvent, getStats, getCollectionReport, getDiscountReport } from './state/events.selectors';
import { RouterFacadeService } from '../../router-facade.service';
import {
  EventsGetDashboardStatisticsAction,
  EventsGetCollectionReportAction,
  EventsGetDiscountReportAction,
} from './state/events.actions';
import { ModelFacadeService } from '../model/model-facade.service';

@Injectable({
  providedIn: 'root',
})
export class EventsFacadeService {
  constructor(
    private store: Store<any>,
    private routerFacadeService: RouterFacadeService,
    private modelFacadeService: ModelFacadeService
  ) {}

  getEvents() {
    return this.store.pipe(select(getEvents));
  }

  getSelectedEvent() {
    return this.routerFacadeService.getParamFromRouter('eventid').pipe(
      map(eventID => +eventID),
      switchMap((eventID: number) => this.store.pipe(select(getEvent({ id: eventID }))))
    );
  }

  getEventsWithModelCounts() {
    const events$ = this.getEvents();
    const models$ = this.modelFacadeService.getAll().pipe(
      map(models =>
        _(models)
          .groupBy('eventId')
          .value()
      )
    );

    return combineLatest([events$, models$]).pipe(
      map(([events, models]) =>
        events.map(d => ({
          ...d,
          models: _(models[+d.id])
            .groupBy('status')
            .value(),
        }))
      )
    );
  }

  getEventStatistics({ eventID }) {
    this.store.dispatch(new EventsGetDashboardStatisticsAction({ eventID }));

    return this.store.pipe(select(getStats({ eventID })));
  }

  getEventsCollectionReport({ eventIDs, regions, wave }) {
    this.store.dispatch(new EventsGetCollectionReportAction({ eventIDs, regions, wave }));
  }

  getCollectionReportData({ eventIDs, regions, wave }: { eventIDs: string[]; regions: string[]; wave: string }) {
    return this.store.pipe(select(getCollectionReport({ eventIDs, regions, wave })));
  }

  getEventsDiscountReport({ eventIDs, regions, wave }: { eventIDs: string[]; regions: string[]; wave: string }) {
    this.store.dispatch(new EventsGetDiscountReportAction({ eventIDs, regions, wave }));
  }

  getDiscountReportData({ eventIDs, regions, wave }: { eventIDs: string[]; regions: string[]; wave: string }) {
    return this.store.pipe(select(getDiscountReport({ eventIDs, regions, wave })));
  }
}
