import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';

import { map, catchError, switchMap, mergeMap } from 'rxjs/operators';

import { UserService } from './user.service';
import * as user from './user.actions';

import { Observable, of } from 'rxjs';
import { GetHelpService } from '../features/get-help/get-help.service';

@Injectable()
export class UserEffects {
  @Effect()
  update$: Observable<Action> = this.actions$.pipe(
    ofType(user.USERGETCURRENTUSER),
    switchMap(() =>
      this.userService.getCurrentUser().pipe(
        map(data => new user.UserGetSuccessAction(data)),
        catchError(error => of(new user.UserGetErrorAction(error)))
      )
    )
  );

  @Effect()
  getAdmin$: Observable<Action> = this.actions$.pipe(
    ofType(user.USERGETADMIN),
    switchMap(() =>
      this.userService.getAdmin().pipe(
        map(data => new user.UserGetAdminSuccessAction(data))
        // catchError((error) => of())
      )
    )
  );

  @Effect()
  setSetting$: Observable<Action> = this.actions$.pipe(
    ofType(user.USERSETORGSETTING),
    mergeMap((action: user.UserSetOrgSettingAction) =>
      this.userService.setSetting(action.payload).pipe(
        map(data => new user.UserSetOrgSettingSuccessAction({ ...data, ...action.payload })),
        catchError(error => of(new user.UserSetOrgSettingFailureAction(error)))
      )
    )
  );

  @Effect()
  getOrgZones$: Observable<Action> = this.actions$.pipe(
    ofType(user.USERGETORGZONES),
    switchMap(() =>
      this.userService.getOrgZones().pipe(
        map(data => new user.UserGetOrgZonesActionSuccess(data)),
        catchError(error => of(new user.UserGetOrgZonesActionFailure(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  getOrgZonesFailure$: Observable<any> = this.actions$.pipe(
    ofType(user.USERGETORGZONESFAILURE),
    switchMap(() => {
      this.getHelpService.showSnackbarError(user.USERGETORGZONESFAILURE);

      return of(false);
    })
  );

  @Effect()
  getMerchHierarchyReferenceData$: Observable<Action> = this.actions$.pipe(
    ofType(user.USERGETMERCHHIERARCHY),
    switchMap(() =>
      this.userService.getMerchHierarchy().pipe(
        map(data => new user.UserGetMerchHierarchyActionSuccess(data)),
        catchError(error => of(new user.UserGetMerchHierarchyActionFailure(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  getMerchHierarchyReferenceDataFailure$: Observable<any> = this.actions$.pipe(
    ofType(user.USERGETMERCHHIERARCHYFAILURE),
    switchMap(() => {
      this.getHelpService.showSnackbarError(user.USERGETMERCHHIERARCHYFAILURE);

      return of(false);
    })
  );

  @Effect()
  getOrgSeasons$: Observable<Action> = this.actions$
    .pipe(
      ofType(user.USERGETORGSEASONS),
      switchMap(() =>
        this.userService
          .getOrgSeasons()
          .pipe(
            map((data) => new user.UserGetOrgSeasonsSuccessAction(data)),
            catchError((error) => of(new user.UserGetOrgSeasonsFailureAction(error)))
          )
      )
    );

  @Effect()
  mapMerchHierarchyAction$: Observable<Action> = this.actions$.pipe(
    ofType(user.USERMAPMERCHHIERARCHY),
    switchMap((action: user.UserMapMerchHierarchyAction) =>
      this.userService.mapMerchHierarchy(action.payload).pipe(
        map(() => new user.UserGetMerchHierarchyAction()),
        catchError(error => of(new user.UserMapMerchHierarchyFailureAction(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  mapMerchHierarchyActionFailure$: Observable<any> = this.actions$.pipe(
    ofType(user.USERMAPMERCHHIERARCHYFAILURE),
    switchMap((action: user.UserMapMerchHierarchyFailureAction) => {
      this.getHelpService.showSnackbarError(user.USERMAPMERCHHIERARCHYFAILURE);

      return of(false);
    })
  );

  @Effect()
  setVatRateAction$: Observable<Action> = this.actions$.pipe(
    ofType(user.USERSETVATRATE),
    switchMap((action: user.UserSetVatRateAction) =>
      this.userService.setVatRate(action.payload).pipe(
        map(() => new user.UserGetOrgZonesAction()),
        catchError(error => of(new user.UserSetVatRateFailureAction(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  setVatRateActionFailure$: Observable<any> = this.actions$.pipe(
    ofType(user.USERSETVATRATEFAILURE),
    switchMap((action: user.UserSetVatRateFailureAction) => {
      this.getHelpService.showSnackbarError(user.USERSETVATRATEFAILURE);
      return of(false);
    })
  );

  @Effect()
  getTerminologySchema$: Observable<Action> = this.actions$.pipe(
    ofType(user.USERGETTERMINOLOGYSCHEMA),
    mergeMap((action: user.GetTerminologySchemaAction) =>
      this.userService.getTerminologySchema(action.payload).pipe(
        map(data => new user.GetTerminologySchemaSuccessAction(data)),
        catchError(error => of(new user.GetTerminologySchemaFailureAction(error)))
      )
    )
  );

  constructor(private userService: UserService, private actions$: Actions, private getHelpService: GetHelpService) {}
}
