export interface Variant {
  id?; // : number;
  eventId?: number;
  alias?: string;
  startDate; // : string;
  endDate; // : string;
  minDisc: number;
  maxDisc: number;
  optimisationType: string;
  optimisationTarget: number;
  minMargin: number;
  wave: number;
  region; // : string;
  ladderType: string;
  discountMethod: string;
}

export interface CreateVariantParams {
  eventId: number;
  minDisc: number;
  maxDisc: number;
  startDate: string;
  endDate: string;
  optimisationType: string;
  optimisationTarget: number;
  minMargin?: number;
  alias: string;
  wave: number;
  region: string;
  ladderType: string;
  discountMethod: string;
}

export function isCreateVariantParams(obj: any): obj is CreateVariantParams {
  return (
    obj &&
    (obj as CreateVariantParams).eventId &&
    String((obj as CreateVariantParams).minDisc) &&
    String((obj as CreateVariantParams).maxDisc) &&
    (obj as CreateVariantParams).startDate &&
    (obj as CreateVariantParams).endDate &&
    (obj as CreateVariantParams).optimisationType &&
    String((obj as CreateVariantParams).optimisationTarget) &&
    (obj as CreateVariantParams).alias &&
    (obj as CreateVariantParams).wave &&
    (obj as CreateVariantParams).region &&
    (obj as CreateVariantParams).ladderType &&
    !!(obj as CreateVariantParams).discountMethod
  );
}

export interface UpdateVariantParams {
  minDisc: number;
  maxDisc: number;
  startDate: string;
  endDate: string;
  optimisationType: string;
  optimisationTarget: number;
  minMargin?: number;
  alias: string;
  ladderType: string;
  discountMethod: string;
}

export function isUpdateVariantParams(obj: any): obj is UpdateVariantParams {
  return (
    obj &&
    String((obj as UpdateVariantParams).minDisc) &&
    String((obj as UpdateVariantParams).maxDisc) &&
    (obj as UpdateVariantParams).startDate &&
    (obj as UpdateVariantParams).endDate &&
    (obj as UpdateVariantParams).optimisationType &&
    String((obj as UpdateVariantParams).optimisationTarget) &&
    (obj as UpdateVariantParams).alias &&
    (obj as UpdateVariantParams).ladderType &&
    !!(obj as UpdateVariantParams).discountMethod
  );
}
