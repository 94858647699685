import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import * as _ from 'lodash';

import { Variant } from '../../../../../../../api/abstracts/planning';
import { forkJoin, Observable, of } from 'rxjs';
import { DATE_FORMAT } from '../../abstracts';

@Injectable({
  providedIn: 'root',
})
export class VariantsService {
  constructor(private http: HttpClient) {}

  getVariants(): Observable<Variant[]> {
    return this.http.get<Variant[]>('/api/variant');
  }

  delete({ id }: { id: number }): Observable<void> {
    return this.http.delete<void>(`/api/variant/${id}`);
  }

  createVariantWizard(variants: Array<Variant & { uuid }>): Observable<Variant[]> {
    if (variants.length === 0) {
      return of([]);
    }
    // @ts-ignore
    const requests = variants.map(params => this.createVariant({ ...params, optimisationType: 'Sell Through' }));

    return forkJoin(requests) as Observable<Variant[]>;
  }

  createVariant(params: Variant & { uuid?; wave: string; startDate: moment.Moment; endDate: moment.Moment }): Observable<Variant> {
    const variant = {
      eventId: params.eventId,
      minDisc: params.minDisc || params.minDisc === 0 ? params.minDisc / 100 : '',
      maxDisc: params.maxDisc || params.maxDisc === 0 ? params.maxDisc / 100 : '',
      startDate: (params.startDate as moment.Moment).format(DATE_FORMAT),
      endDate: (params.endDate as moment.Moment).format(DATE_FORMAT),
      optimisationType: params.optimisationType,
      optimisationTarget: params.optimisationTarget || params.optimisationTarget === 0 ? params.optimisationTarget / 100 : '',
      alias: params.alias,
      wave: (params.wave as string).replace(/Wave /g, ''),
      region: params.region,
      ladderType: params.ladderType.toLowerCase(),
      discountMethod: params.discountMethod,
    };

    if (params.minMargin != null) {
      // tslint:disable-next-line: no-string-literal
      variant['minMargin'] = params.minMargin || params.minMargin === 0 ? params.minMargin / 100 : '';
    }

    return this.http.post<Variant>('/api/variant', { variant }).pipe(map(data => ({ ...data, uuid: params.uuid })));
  }

  updateVariant(params: Variant & { uuid?; wave: string; startDate: moment.Moment; endDate: moment.Moment }): Observable<Variant> {
    const variant = {
      minDisc: params.minDisc,
      maxDisc: params.maxDisc,
      startDate: params.startDate.format(DATE_FORMAT),
      endDate: params.endDate.format(DATE_FORMAT),
      optimisationType: params.optimisationType,
      optimisationTarget: params.optimisationTarget,
      alias: params.alias,
      ladderType: params.ladderType.toLowerCase(),
      discountMethod: params.discountMethod,
    };

    if (params.minMargin != null) {
      // tslint:disable-next-line: no-string-literal
      variant['minMargin'] = params.minMargin != null ? params.minMargin : '';
    }

    return this.http.post<Variant>(`/api/variant/${params.id}`, { variant });
  }
}
