import { Component, OnInit, Input, Inject } from '@angular/core';
import * as _ from 'lodash';
import { ModelFacadeService } from '../../../model-facade.service';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Model } from '../../../state/model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { VariantDetails } from '../../../../event/event';
import { Model as AbstractModel } from '../../../../../../../../../../api/abstracts/planning';

@Component({
  selector: 'app-model-detail-variants-information',
  templateUrl: './model-detail-variants-information.component.html',
  styleUrls: ['./model-detail-variants-information.component.scss'],
})
export class ModelDetailVariantsInformationComponent implements OnInit {
  @Input()
  modelDetails;

  disableApply = true;
  public variantForm: FormGroup;
  models$: Observable<Array<AbstractModel>>;
  variantData = [];
  selectedModelVariants = [];
  variantDetails = [];
  modelsUpdated = [];
  variantIndexes = [];
  constructor(
    private modelFacadeService: ModelFacadeService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ModelDetailVariantsInformationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { models: Model[]; selectedWave: string }
  ) {
    this.variantForm = new FormGroup({
      formArrayName: this.formBuilder.array([]),
    });
  }

  ngOnInit() {
    const controlArray = this.variantForm.get('formArrayName') as FormArray;
    this.models$ = this.modelFacadeService.getSelectedModels();
    this.variantData = _(this.data.models)
      .map(model => {
        const variantIndex = _.findIndex(model.variant, (variant: VariantDetails) => +variant.id === +model.variantId);
        const selectedVariant = _.find(model.variant, (variant: VariantDetails) => +variant.id === +model.variantId);
        const variantData = {
          name: model.name,
          region: model.region,
          modelId: model.id,
          variants: model.variant,
          variantId: model.variantId,
          wave: model.wave,
          status: model.status,
          selectedVariant,
          variantIndex,
        };
        return variantData;
      })
      .value();

    this.variantData.forEach(model => {
      const form = this.formBuilder.group({
        name: [model.name],
        variant: [model.selectedVariant],
      });
      controlArray.push(form);
    });
    this.variantIndexes = this.variantData.map(({ variantIndex }) => variantIndex);
  }

  onVariantChange(index, id, selected) {
    this.disableApply = false;
    const variantSelected = _.find(this.variantData, variant => +variant.modelId === +id);
    const updatedVariantIndex = _.findIndex(variantSelected.variants, (variant: VariantDetails) => +variant.id === +selected.value.id);
    this.variantIndexes[index] = updatedVariantIndex;

    const modelUpdated = _.find(this.modelsUpdated, d => d.id === id);
    if (modelUpdated) {
      modelUpdated.selected = selected;
    } else {
      this.modelsUpdated.push({
        id,
        selected,
      });
    }
  }

  compareFunction(o1: any, o2: any) {
    return o1 === o2;
  }

  applyVariant() {
    _(this.modelsUpdated).forEach(model => {
      this.modelFacadeService.applyVariant({ model, variantId: model.selected.value.id });
    });
    const modelIds = this.modelsUpdated.map(model => model.id);
    this.dialogRef.close(modelIds);
  }
}
