import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import * as _ from 'lodash';

import { Model } from './model';
import * as modelActions from './model.actions';
import { Model as AbstractModel } from '../../../../../../../../api/abstracts/planning';
import { EventModelStatus } from '../../../abstracts/event-status.type';

export interface ModelState extends EntityState<AbstractModel> {
  loadingEntities: boolean;
}

export const modelAdapter: EntityAdapter<AbstractModel> = createEntityAdapter<AbstractModel>(); // { selectId: entity => entity.id });

export const initialState: ModelState = modelAdapter.getInitialState({
  loadingEntities: false,
});

const modelReducer = createReducer(
  initialState,
  on(modelActions.ModelLoadAll, (state: ModelState) => ({ ...state, loadingEntities: true })),
  on(modelActions.ModelLoadAllSuccess, (state: ModelState, { entities }) => ({
    ...modelAdapter.addAll(entities, state),
    loadingEntities: false,
  })),
  on(modelActions.ModelCreateSuccess, (state: ModelState, { entities }) => ({ ...modelAdapter.addMany(entities, state) })),
  on(modelActions.ModelSubmit, (state: ModelState, { model }) => ({
    // todo: implement reducer for ModelSubmitFailure
    ...modelAdapter.updateOne({ id: model.id, changes: { status: EventModelStatus[1], statusCode: 1 } }, state),
  })),
  on(modelActions.ModelRetract, (state: ModelState, { model }) => ({
    // todo: implement reducer for ModelRetractFailure
    ...modelAdapter.updateOne({ id: model.id, changes: { status: EventModelStatus[0], statusCode: 0 } }, state),
  })),
  on(modelActions.ModelApprove, (state: ModelState, { model }) => ({
    ...modelAdapter.updateOne({ id: model.id, changes: { status: EventModelStatus[2], statusCode: 2 } }, state),
  })),
  on(modelActions.ModelApproveSuccess, (state: ModelState, { update }) => {
    const getRelatedModels = (id: string | number) => {
      const compareWith = state.entities[id];
      if (compareWith) {
        return _.filter(
          state.entities,
          model =>
            model.id !== compareWith.id &&
            model.eventId === compareWith.eventId &&
            model.region === compareWith.region &&
            model.departmentId === compareWith.departmentId &&
            +model.wave > +compareWith.wave
        );
      }

      return [];
    };

    const updateRelatedModels = _(update)
      .map(({ id }) => getRelatedModels(id))
      .flatten()
      .map(({ id }) => ({ id, changes: { lines: null } }))
      .value();

    return { ...modelAdapter.updateMany([...update, ...updateRelatedModels], state) };
  }),
  on(modelActions.ModelApplyVariantSuccess, modelActions.ModelGetDiscountDistributionSuccess, (state: ModelState, { update }) => ({
    ...modelAdapter.updateOne(update, state),
  })),
  on(
    modelActions.ModelSubmitSuccess,
    modelActions.ModelRetractSuccess,
    modelActions.ModelUpdateSuccess,
    (state: ModelState, { update }) => ({
      ...modelAdapter.updateMany(update, state),
    })
  ),
  on(modelActions.ModelDeleteSuccess, (state: ModelState, { id }) => ({ ...modelAdapter.removeOne(id, state) })),
  on(modelActions.ModelLoadLinesSuccess, (state: ModelState, { updates }) => ({ ...modelAdapter.updateMany(updates, state) })),
  on(modelActions.ModelEditLineNoteSuccess, (state: ModelState, { modelId, note, skuId }) => {
    const newState = _.cloneDeep(state);
    const line = _.find(newState.entities[modelId].lines, d => d.skuId === skuId);
    line.note = note;

    return newState;
  }),
  on(modelActions.ModelTakeUntakeLinesSuccess, (state: ModelState, { modelLines }) => {
    const newState = _.cloneDeep(state);

    _(modelLines)
      .groupBy('modelId')
      .forEach((values, modelID) => {
        newState.entities[modelID].linesTaken = _.first(values).lineCount;
      });

    modelLines.forEach(({ skuId, modelId, taken }) => {
      const line = _.find(newState.entities[modelId].lines, d => d.skuId === skuId);
      if (line) {
        line.taken = +taken === 0 ? 'No' : 'Yes';
      }
    });

    return newState;
  }),
  on(modelActions.ModelRefreshSuccess, (state, { models, lines }) => {
    const modelsByID = _(models)
      .keyBy('id')
      .value();
    _(lines).forEach((listOfLines, id) => (modelsByID[id].lines = listOfLines));

    const updates = _(modelsByID)
      .map((model, id) => ({ id, changes: model }))
      .value();

    return { ...modelAdapter.updateMany(updates, state) };
  }),
  on(modelActions.ModelGetLineOverridePrediction, (state, { modelID, skuList, override }) => {
    const newState = _.cloneDeep(state);
    skuList.forEach(skuID => {
      const line = _.find(newState.entities[modelID].lines, d => d.skuId === skuID);
      line.lastOverride = line.ovrPrice;
      line.ovrPrice = override;
    });
    newState.loadingEntities = true;
    return newState;
  }),
  on(modelActions.ModelGetLineOverridePredictionFailure, (state, { modelID, skuList }) => {
    const newState = _.cloneDeep(state);
    skuList.forEach(skuID => {
      const line = _.find(newState.entities[modelID].lines, d => d.skuId === skuID);
      line.ovrPrice = line.lastOverride;
    });

    return state;
  }),
  // this should use updateOne, need the API to send back all properties of a line
  on(modelActions.ModelGetLineOverridePredictionSuccess, (state, { lines }) => {
    const newState = _.cloneDeep(state);

    lines.forEach(
      ({ skuId, modelId, ovrPrice, fcstMdSales, fcstEndSales, fcstEndStock, fcstSpend, finalDisc, finalMargin, finalPrice, fcstEndSt }) => {
        const line = _.find(newState.entities[modelId].lines, d => d.skuId === skuId);
        if (line) {
          line.ovrPrice = ovrPrice;
          line.fcstMdSales = fcstMdSales;
          line.fcstEndSales = fcstEndSales;
          line.fcstEndStock = fcstEndStock;
          line.fcstSpend = fcstSpend;
          line.finalDisc = finalDisc;
          line.finalMargin = finalMargin;
          line.finalPrice = finalPrice;
          line.fcstEndSt = fcstEndSt;
        }
      }
    );
    newState.loadingEntities = false;
    return {
      ...newState,
      loading: false,
    };
  })
);

export function reducer(state: ModelState | undefined, action: Action) {
  return modelReducer(state, action);
}

export const { selectIds, selectEntities, selectAll, selectTotal } = modelAdapter.getSelectors();
